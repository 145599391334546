import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Helmet } from 'react-helmet';

// navbar type
import Sidebar2 from 'components/nav/Sidebar2';
import BottomNav from 'components/nav/BottomNav';
import Header from 'components/nav/Header';
import { Row } from 'components/generic/Layout';
import Footer from 'components/Footer';

const STATIC_PAGES = [
  'terms-and-conditions',
  'privacy-policy',
  'transaction-processed',
  'transaction-cancelled',
  '',
  'about',
  'login',
  'signup',
  'forgot-password',
  'careers',
  'ontario',
  'books',
];

export default function Nav(props) {
  const location = useLocation();
  const isMobile = useMedia('(max-width: 625px)');

  let defaultTitle = location.pathname
    .replace('/', '')
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.substr(1));

  defaultTitle = defaultTitle.join(' ');

  if (!defaultTitle || defaultTitle === '') {
    defaultTitle = 'Sports Betting Hub';
  }

  // const metaDescription =
  //  'Sports Betting Tracking Platform & Odds Comparison. Follow Friends, Social Betting & More.';

  // no header or footer if complete purchase from the app
  if (
    location.pathname.includes('/complete-purchase') &&
    location.search.includes('?mobile=true')
  ) {
    return <>{props.children}</>;
  }

  if (
    location.pathname === '/' ||
    location.pathname.includes('/signup') ||
    location.pathname.includes('/social-callback') ||
    location.pathname === '/setup-betlink' ||
    location.pathname === '/setup-referral-code' ||
    location.pathname === '/setup-profile' ||
    location.pathname.includes('/login') ||
    location.pathname.includes('/ontario') ||
    location.pathname === '/forgot-password' ||
    location.pathname.includes('/promo') ||
    location.pathname.includes('/onboarding') ||
    location.pathname.includes('/groups') ||
    location.pathname.includes('/vip') ||
    location.pathname.includes('/events') ||
    location.pathname.includes('/best-sportsbooks') ||
    location.pathname === '/books' ||
    location.pathname === '/books/' ||
    location.pathname === '/intake' ||
    location.pathname === '/intake/' ||
    location.pathname.includes('/reschedule') ||
    (location.pathname.includes('session') &&
      !location.pathname.includes('staff') &&
      location.pathname.split('/')?.length > 2)
  ) {
    return props.children;
  }

  if (STATIC_PAGES.includes(location.pathname.replace('/', ''))) {
    return (
      <>
        <Helmet
          defaultTitle={`betstamp | ${defaultTitle}`}
          titleTemplate={'betstamp | %s'}
          //meta={[
          //  { name: `description`, content: metaDescription },
          //  { property: `og:title`, content: `betstamp app` },
          //  { property: `og:description`, content: metaDescription },
          //  { property: `og:type`, content: `website` },
          //  { property: `twitter:card`, content: `app` },
          //  {
          //    property: `twitter:creator`,
          //    content: `@betstamp`,
          //  },
          //  { property: `twitter:title`, content: `betstamp app` },
          //  { property: `twitter:description`, content: metaDescription },
          //]}
        />
        <Header />
        {props.children}
        <Footer />
      </>
    );
  }

  if (isMobile) {
    return (
      <>
        <Header />
        <Helmet
          defaultTitle={`betstamp | ${defaultTitle}`}
          titleTemplate={'betstamp | %s'}
          //meta={[
          //  { name: `description`, content: metaDescription },
          //  { property: `og:title`, content: `betstamp app` },
          //  { property: `og:description`, content: metaDescription },
          //  { property: `og:type`, content: `website` },
          //  { property: `twitter:card`, content: `app` },
          //  {
          //    property: `twitter:creator`,
          //    content: `@betstampapp`,
          //  },
          //  { property: `twitter:title`, content: `betstamp app` },
          //  { property: `twitter:description`, content: metaDescription },
          //]}
        />
        {props.children}
        {!location.pathname.includes('/sportsbooks') &&
          !location.pathname.includes('/intake') &&
          !location.pathname.includes('/onboarding') &&
          !location.pathname.includes('/staff') &&
          !location.pathname.includes('/session') &&
          !location.pathname.includes('/questionnaires') && (
            <div style={{ position: 'fixed', bottom: 0, width: '100vw' }}>
              <BottomNav />
            </div>
          )}
      </>
    );
  }

  return (
    <>
      <Helmet
        defaultTitle={`betstamp | ${defaultTitle?.replace('Staff/', '')}`}
        titleTemplate={'betstamp | %s'}
        //meta={[
        //  { name: `description`, content: metaDescription },
        //  { property: `og:title`, content: `betstamp app` },
        //  { property: `og:description`, content: metaDescription },
        //  { property: `og:type`, content: `website` },
        //  { property: `twitter:card`, content: `app` },
        //  {
        //    property: `twitter:creator`,
        //    content: `@betstampapp`,
        //  },
        //  { property: `twitter:title`, content: `betstamp app` },
        //  { property: `twitter:description`, content: metaDescription },
        //]}
      />
      <Row
        style={{
          flexFlow: 'row nowrap',
          justifyContent: 'flex-start',
          width: '100vw',
          height: '100vh',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <div style={{ flex: '0 0 min-content', overflowY: 'auto' }}>
          <Sidebar2 />
        </div>
        <div
          id={'infinite-scroll-target'}
          style={{ flex: 1, overflowX: 'hidden' }}
        >
          {props.children}
        </div>
      </Row>
    </>
  );
}
