import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  IoBookOutline,
  IoCashOutline,
  IoAnalytics,
  IoFileTrayFullOutline,
  IoChevronDown,
  IoChevronUp,
  IoHelpCircleOutline,
  IoAmericanFootball,
  IoReaderOutline,
  IoGitCompare,
  IoPlayForward,
  IoStar,
  IoPeople,
  IoExtensionPuzzleOutline,
} from 'react-icons/io5';
import { MdNewReleases } from 'react-icons/md';

// utils
import { kFormatter } from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import ProfilePic from 'components/generic/ProfilePic';
import {
  // BetstampStamp,
  // BetstampWordBlueText,
  BetlinkStamp,
  BetstampStampInverted,
  BetstampWordBlueText,
} from 'components/generic/Logos';

// actions
import { getPicksStats } from 'actions';
import TeamLeagueLogo from 'components/TeamLeagueLogo';

// custom react query hooks
import { useUserGroups } from 'react-query/global-hooks';

const STATIC_BAR_WIDTH = '64px';
const FLOATING_BAR_WIDTH = '255px';

const StaticBar = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background-color: var(--color-fg);
  z-index: 100;
  width: ${STATIC_BAR_WIDTH};
  overflow-x: hidden;
  &:hover + .floating-sidebar {
    pointer-events: auto;
    box-shadow: 2px 0 8px 0 var(--color-shadow);
    width: ${FLOATING_BAR_WIDTH};
    z-index: 1000;
  }
`;

const FloatingBar = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${STATIC_BAR_WIDTH};
  overflow-x: hidden;
  white-space: nowrap;
  background-color: var(--color-fg);
  pointer-events: none;
  transition: all var(--std-transition);
  &:hover {
    pointer-events: auto;
    width: ${FLOATING_BAR_WIDTH};
    box-shadow: 2px 0 8px 0 var(--color-shadow);
    z-index: 1000;
  }

  & > nav > a > span {
    opacity: 0;
  }
  &:hover > nav > a > span {
    opacity: 1;
  }
  & > nav > a > svg {
    opacity: 0;
  }
  &:hover > nav > a > svg {
    opacity: 1;
  }
`;

const NavLinks = styled.nav`
  z-index: 100;
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  & > a {
    min-height: 50px;
    text-decoration: none;
    color: var(--color-text);
    margin: 0;
    opacity: 0.8;
    transition: all var(--std-transition);
    cursor: pointer;
    padding: var(--space-sm) 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: hidden;
    align-items: center;
    box-sizing: border-box;
  }
  & > a:hover {
    opacity: 1;
    background-color: var(--color-bg);
  }
  & > a > span {
    display: ${props => {
    if (props.open) {
      return 'block';
    } else {
      return 'none';
    }
  }};
  }
`;

export default function Sidebar2(props) {
  const userGroups = useUserGroups();
  return (
    <>
      <StaticBar className={'static-sidebar'}>
        <ActualLinks floating={false} userGroups={userGroups} />
      </StaticBar>
      <FloatingBar className={'floating-sidebar'}>
        <ActualLinks floating={true} userGroups={userGroups} />
      </FloatingBar>
    </>
  );
}

function SidebarLink(props) {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const Icon = props.icon;

  useEffect(() => {
    if (
      props.watchLocations &&
      props.watchLocations.includes(location.pathname)
    ) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [location.pathname, props.watchLocations]);

  return (
    <>
      <NavLink
        exact
        to={props.link}
        style={{
          paddingLeft: props.subLink ? 'var(--space-sm)' : '0',
        }}
        activeStyle={{
          opacity: 1,
          backgroundColor: 'var(--color-bg)',
          color: 'var(--color-primary)',
        }}
      >
        {props.betlink ? (
          <BetlinkStamp
            blue={location.pathname.includes('betlink')}
            style={{
              width: '26px',
              height: '26px',
              boxSizing: 'unset',
              padding: '0 19px',
            }}
          />
        ) : (
          <>
            {Icon && (
              <Icon
                title={props.title}
                size={props.subLink ? '18px' : '26px'}
                style={{
                  padding: props.subLink ? '0 21px' : '0 19px',
                  boxSizing: 'unset',
                }}
              />
            )}
          </>
        )}
        {props.new && (
          <div style={{ position: 'relative', backgroundColor: 'pink' }}>
            <MdNewReleases
              size={'20px'}
              color="var(--color-danger)"
              style={{ position: 'absolute', right: '10px', top: '-17px' }}
            />
          </div>
        )}
        <span>{props.title}</span>
        {props.expandable && (
          <Col
            style={{
              flex: 0,
              alignItems: 'flex-end',
              marginLeft: 'auto',
            }}
            onClick={e => {
              e.preventDefault();
              setExpanded(!expanded);
            }}
          >
            {expanded ? (
              <IoChevronUp size={22} style={{ cursor: 'pointer' }} />
            ) : (
              <IoChevronDown size={22} style={{ cursor: 'pointer' }} />
            )}
          </Col>
        )}
      </NavLink>
      {expanded && <>{props.children}</>}
    </>
  );
}

function ActualLinks(props) {
  const dispatch = useDispatch();

  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    user: state.authReducer.user,
    hist_date_after: state.picksReducer.hist_date_after,
    hist_date_before: state.picksReducer.hist_date_before,
    pickStats: state.picksReducer.pickStats,
    selectedLeagues: state.scheduleReducer.selectedLeagues,
  }));

  const {
    userToken,
    user,
    hist_date_after,
    hist_date_before,
    pickStats,
    selectedLeagues,
  } = reduxProps;

  useEffect(() => {
    if (userToken) {
      dispatch(getPicksStats(hist_date_after, hist_date_before));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { floating, userGroups } = props;

  return (
    <>
      <NavLinks open={floating}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '58px',
          }}
        >
          {floating ? (
            <Link to="/" style={{ height: '58px' }}>
              <BetstampWordBlueText height={58} />
            </Link>
          ) : (
            <BetstampStampInverted height={32} />
          )}
        </div>

        <SidebarLink
          title="Games"
          link="/games"
          icon={
            selectedLeagues.length > 0
              ? () => (
                <TeamLeagueLogo
                  league={selectedLeagues[0]}
                  style={{
                    width: '26px',
                    height: '26px',
                    padding: '0 19px',
                    boxSizing: 'unset',
                  }}
                />
              )
              : IoAmericanFootball
          }
        />

        <SidebarLink
          title="Odds Comparison"
          link="/odds-comparison"
          icon={IoGitCompare}
        />

        <SidebarLink title="Futures" link="/futures" icon={IoPlayForward} />

        <SidebarLink title="My Picks" link="/my-picks" icon={IoBookOutline} />

        <SidebarLink
          title="Accounting"
          link="/accounting"
          icon={IoFileTrayFullOutline}
        />

        <SidebarLink title="Analysis" link="/analysis" icon={IoAnalytics} />

        <SidebarLink
          title="Find Bettors"
          link="/find-bettors"
          icon={IoPeople}
        />
        {/*<SidebarLink title="Find Bets" link="/find-bets" icon={IoStar} />*/}

        {user?.packages?.length > 0 && (
          <SidebarLink
            title="Manage Packages"
            link="/sell-picks"
            icon={IoCashOutline}
          />
        )}
        <a
          href="https://betstamp.com/sportsbook-reviews"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'var(--color-text)',
            padding: 'var(--space-sm) 0',
            width: '100%',
            boxSizing: 'border-box',
            opacity: 0.8,
            transition: 'all var(--std-transition)',
            cursor: 'pointer'
          }}
          onMouseOver={e => (e.currentTarget.style.opacity = 1)}
          onMouseOut={e => (e.currentTarget.style.opacity = 0.8)}
        >
          <IoReaderOutline
            title="Sportsbooks"
            size="26px"
            style={{
              padding: '0 19px',
              boxSizing: 'unset',
              opacity: 1
            }}
          />
          <span>Sportsbooks</span>
        </a>
        {/*<SidebarLink
          title="Betlink"
          link="/betlink"
          icon={BetlinkStamp}
          betlink
        />*/}
        {user?.is_staff &&
          (userGroups.isOnboardingHost ||
            userGroups.isDeveloper ||
            userGroups.isOnboardingHost ||
            userGroups.isOnboardingFollowUpHost ||
            userGroups.isOnboardingSalesTeam ||
            userGroups.isExecutive ||
            userGroups.isAffiliateManager ||
            userGroups.isFullDeveloper ||
            userGroups.isAffiliateDashboard) && (
            <SidebarLink
              title="Staff Dashboard"
              link="/staff/my-dashboard"
              icon={IoExtensionPuzzleOutline}
            />
          )}

        <NavLink exact to="/settings" style={{ opacity: 1, border: 'none' }}>
          <Row
            style={{
              flexFlow: 'row nowrap',
              padding: 'var(--space-xxxs) 0',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <ProfilePic
              user={user}
              style={{
                width: '32px',
                height: '32px',
                marginLeft: '16px',
                marginRight: '8px',
              }}
            />
            {floating && (
              <h6 style={{ margin: 0 }}>
                {userToken ? (
                  <>{user?.username ? user.username : 'My Profile'}</>
                ) : (
                  'Login/Signup'
                )}
              </h6>
            )}
          </Row>
        </NavLink>
        {/* </NavLinks>

<NavLinks style={{ justifyContent: 'flex-end' }}> */}
        {userToken && floating ? (
          <>
            <NavLink
              style={{
                flex: 0,
                width: '100%',
                padding: 'var(--space-xs) 0',
                boxSizing: 'border-box',
                opacity: 1,
              }}
              exact
              to="/my-picks"
            >
              <Col>
                {pickStats['pending_today'].length > 0 && (
                  <>
                    <b style={{ lineHeight: 1 }}>
                      {kFormatter(pickStats['pending_today'][1].num)}
                    </b>
                    <small style={{ lineHeight: 1 }}>Today's Risk</small>
                  </>
                )}
              </Col>
              <Col>
                {pickStats['graded_today'].length > 0 && (
                  <>
                    <AutoColoredAmount
                      symbol={'$'}
                      bold="true"
                      style={{ lineHeight: 1 }}
                    >
                      {pickStats['graded_today'][1].num}
                    </AutoColoredAmount>
                    <small style={{ lineHeight: 1, whiteSpace: 'nowrap' }}>
                      Today's Profit
                    </small>
                  </>
                )}
              </Col>
            </NavLink>
          </>
        ) : (
          <>
            {userToken && (
              <NavLink
                style={{ opacity: 1, padding: '0' }}
                exact
                to="/my-picks"
              >
                <Col>
                  {pickStats['pending_today'].length > 0 && (
                    <>
                      <small style={{ lineHeight: 1, whiteSpace: 'nowrap' }}>
                        <b>{kFormatter(pickStats['pending_today'][1].num)}</b>
                      </small>
                      <small style={{ lineHeight: 1, whiteSpace: 'nowrap' }}>
                        At Risk
                      </small>
                      {/* <small>
                      <AutoColoredAmount symbol={'$'} bold="true">
                        {pickStats['graded_today'][1].num}
                      </AutoColoredAmount>
                    </small> */}
                    </>
                  )}
                </Col>
              </NavLink>
            )}
          </>
        )}
        <a
          href="https://betstamp.com/tutorials"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'var(--color-text)',
            padding: 'var(--space-sm) 0',
            width: '100%',
            boxSizing: 'border-box',
            opacity: 0.8,
            transition: 'all var(--std-transition)',
            cursor: 'pointer'
          }}
          onMouseOver={e => (e.currentTarget.style.opacity = 1)}
          onMouseOut={e => (e.currentTarget.style.opacity = 0.8)}
        >
          <IoHelpCircleOutline
            title="Help"
            size="26px"
            style={{
              padding: '0 19px',
              boxSizing: 'unset',
              opacity: 1
            }}
          />
          <span>Help</span>
        </a>      
      </NavLinks>
    </>
  );
}
