import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// components
import { Row } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import CustomSelect from 'components/generic/Select';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';
import AddEditTags from 'components/AddEditTags';
import EditRiskResultOdds from 'components/EditRiskResultOdds';
import BooksDropdown from 'components/BooksDropdown';
import CalendarInput from 'components/generic/CalendarInput';

// actions
import {
  removeAllBets,
  addBet,
  trackBetslip,
  getPicksStats,
  getTransactionSummary,
  createTransaction,
  changeBetslipMode,
} from 'actions';

const InputsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: auto;
  & > div {
    flex: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: var(--space-xxs) 0;
  }
  & > div > b {
    flex: 1;
    text-align: right;
    padding: 0 var(--space-sm);
  }
`;

export default function AddUnsupported(props) {
  const dispatch = useDispatch();

  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    hist_date_after: state.picksReducer.hist_date_after,
    hist_date_before: state.picksReducer.hist_date_before,
    isTrackingBets: state.betslipReducer.isTrackingBets,
    trackingFailure: state.betslipReducer.trackingFailure,
    bets: state.betslipReducer.bets,
    isCreatingTransaction: state.transactionReducer.isCreatingTransaction,
    createTransactionFailure: state.transactionReducer.createTransactionFailure,
    createTransactionSuccess: state.transactionReducer.createTransactionSuccess,
    transactionSummary: state.transactionReducer.transactionSummary,
  }));

  const {
    user,
    allBooks,
    hist_date_after,
    hist_date_before,
    isTrackingBets,
    trackingFailure,
    bets,
    isCreatingTransaction,
    createTransactionFailure,
    createTransactionSuccess,
    transactionSummary,
  } = reduxProps;

  const { odds_preference } = user;
  const { preloadBet } = props;

  useEffect(() => {
    dispatch(changeBetslipMode('singles'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let startingBook = 1;
  if (props.currentBook && user && user.books && allBooks) {
    startingBook = user.books
      .concat(allBooks)
      .find(b => b.name === props.currentBook);
    startingBook = startingBook ? startingBook : allBooks[3];
  } else if (user && user.books && user.books.length > 0) {
    startingBook = user.books[0];
  } else if (allBooks && allBooks.length > 0) {
    startingBook = allBooks[3];
  } else {
    startingBook = undefined;
  }

  const [mode] = useState(props.mode ? props.mode : 'custom bet');
  const [common, setCommon] = useState({
    date: new Date(),
    book: startingBook,
    tags: [],
  });
  const [bet, setBet] = useState({
    sports: [],
    risk_amount: user?.bet_size || 100,
    result: user?.bet_size || 100,
    odds: 2,
    bet_type: 'CUSTOM',
    custom_desc: '',
  });
  const [trans, setTrans] = useState({
    transaction_type: 'Initial',
    amount: '',
  });

  useEffect(() => {
    if (!common.book && allBooks.length > 0) {
      setCommon({ ...common, book: allBooks[3] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBooks.length]);

  useEffect(() => {
    if (preloadBet) {
      setBet({
        sports: preloadBet?.sports,
        risk_amount: preloadBet?.risk_amount,
        result: preloadBet?.result,
        odds: preloadBet?.odds,
        bet_type: 'CUSTOM',
        custom_desc: preloadBet?.custom_desc,
      });
    }
  }, []);

  const _place = () => {
    if (mode === 'custom bet') {
      let aggBet = {
        ...common,
        ...bet,
        first_date: common.date,
        last_date: common.date,
      };

      dispatch(removeAllBets());
      dispatch(addBet(aggBet));
      dispatch(trackBetslip());
    } else {
      let aggTrans = {
        ...common,
        ...trans,
        book: common.book.id,
        transaction_type: trans.transaction_type.toLowerCase(),
      };

      if (aggTrans.transaction_type === 'overwrite balance') {
        const currentBalance = transactionSummary[common.book.name].total;
        const desiredBalance = parseFloat(trans.amount);
        aggTrans.amount = desiredBalance - currentBalance;
        aggTrans.transaction_type = 'adjustment';
      }

      if (aggTrans.transaction_type === 'withdraw') {
        aggTrans.amount = -1 * Math.abs(aggTrans.amount);
      }

      dispatch(createTransaction(aggTrans));
    }
  };

  useEffect(() => {
    let postUnsupportedSuccess =
      bets.length === 1 && bets[0].tracked && bets[0].bet_type === 'CUSTOM';

    if (postUnsupportedSuccess || createTransactionSuccess) {
      if (postUnsupportedSuccess) {
        dispatch(getPicksStats(hist_date_after, hist_date_before));
      }
      if (createTransactionSuccess) {
        dispatch(getTransactionSummary());
      }
      props.hideModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bets[0]?.tracked, createTransactionSuccess]);

  const betDisabled =
    bet.risk_amount <= 0 ||
    bet.sports.length < 1 ||
    !bet.odds ||
    bet.custom_desc.trim() === '';

  const transDisabled =
    trans.amount.replace('-', '').replace('$', '').trim() === '';

  return (
    <>
      {(trackingFailure || (bets.length > 0 && bets[0].errors)) && (
        <div>
          <ErrorDisplay
            error={trackingFailure || bets[0].errors}
            message={'creating this wager'}
            retry={() => this._place()}
          />
        </div>
      )}

      {createTransactionFailure && (
        <div>
          <ErrorDisplay
            error={createTransactionFailure}
            message={'creating this transaction'}
            retry={() => this._place()}
          />
        </div>
      )}

      <InputsWrapper>
        <div style={{ marginTop: 0 }}>
          <b>Date</b>
          <div style={{ flex: 2 }}>
            <Row>
              <CalendarInput
                value={common.date}
                onChange={date => {
                  const hour = moment(date).hours();
                  const min = moment(date).minutes();
                  let newDate = moment(date).hour(hour).minute(min);
                  setCommon({ ...common, date: newDate.toDate() });
                }}
              />
              {mode === 'custom bet' && (
                <AuthTextInput
                  type="time"
                  containerStyle={{
                    minWidth: 'fit-content',
                  }}
                  inputRowStyle={{
                    backgroundColor: 'var(--color-bg)',
                  }}
                  defaultValue={moment(common.date).format('HH:mm')}
                  onChangeText={newTime => {
                    if (!newTime || newTime === '') {
                      return;
                    }
                    const hour = newTime.split(':')[0];
                    const min = newTime.split(':')[1];
                    let newDate = moment(common.date).hour(hour).minute(min);
                    setCommon({ ...common, date: newDate.toDate() });
                  }}
                />
              )}
            </Row>
          </div>
        </div>
        <div>
          <b>Book</b>
          <div style={{ flex: 2 }}>
            <BooksDropdown
              defaultValue={{
                value: preloadBet?.book.value || startingBook?.value,
                label: preloadBet?.book.name || startingBook?.name,
              }}
              onChange={selectedOption => {
                setCommon({
                  ...common,
                  book: {
                    id: selectedOption.value,
                    name: selectedOption.label,
                  },
                });
              }}
            />
          </div>
        </div>

        {mode === 'transaction' && (
          <>
            <div>
              <b>Type</b>
              <div style={{ flex: 2 }}>
                <CustomSelect
                  onChange={opt => {
                    setTrans({ ...trans, transaction_type: opt.value });
                  }}
                  value={{
                    label: trans.transaction_type,
                    value: trans.transaction_type,
                  }}
                  options={[
                    {
                      label: 'Initial',
                      value: 'Initial',
                    },
                    {
                      label: 'Withdraw',
                      value: 'Withdraw',
                    },
                    {
                      label: 'Deposit',
                      value: 'Deposit',
                    },
                    {
                      label: 'Bonus',
                      value: 'Bonus',
                    },
                    {
                      label: 'Adjustment',
                      value: 'Adjustment',
                    },
                    {
                      label: 'Overwrite Balance',
                      value: 'Overwrite Balance',
                    }
                  ]}
                />
              </div>
            </div>
            <div>
              <b>Amount</b>
              <AuthTextInput
                containerStyle={{ flex: 2 }}
                type={'number'}
                inputRowStyle={{
                  backgroundColor: 'var(--color-bg)',
                }}
                placeholder={'Amount'}
                autoCapitalize={'words'}
                onChangeText={newAmount =>
                  setTrans({ ...trans, amount: newAmount })
                }
              />
            </div>
          </>
        )}
        {mode === 'custom bet' && (
          <>
            <div>
              <b>Sport</b>
              <AuthTextInput
                containerStyle={{ flex: 2 }}
                inputRowStyle={{
                  backgroundColor: 'var(--color-bg)',
                }}
                placeholder={'Sport/League (ex. Soccer, Golf, Rugby, etc)'}
                defaultValue={preloadBet?.sports.toString()}
                autoCapitalize={'words'}
                onChangeText={newSports => {
                  const sportArray = newSports.trim().split(',');
                  setBet({ ...bet, sports: sportArray });
                }}
              />
            </div>
          </>
        )}
        <div>
          <b>Description</b>
          <AuthTextInput
            containerStyle={{ flex: 2 }}
            inputRowStyle={{
              backgroundColor: 'var(--color-bg)',
            }}
            type="textarea"
            maxLength={140}
            rows={2}
            placeholder={
              mode === 'custom bet'
                ? 'Description (ex. Tiger Woods to win the Masters)'
                : 'Description (ex. Initial site balance)'
            }
            defaultValue={preloadBet?.custom_desc}
            onChangeText={text => {
              if (mode === 'custom bet') {
                setBet({ ...bet, custom_desc: text });
              } else {
                setCommon({ ...common, description: text });
              }
            }}
          />
        </div>

        {mode === 'custom bet' && (
          <EditRiskResultOdds
            labelFlex={1}
            inputMaxWidth={'700px'}
            oddsPreference={odds_preference}
            defaultRisk={preloadBet?.risk_amount || bet.risk_amount}
            defaultResult={preloadBet?.result || bet.risk_amount}
            defaultOdds={preloadBet?.odds || 2}
            handleChange={changes => {
              setBet({
                ...bet,
                risk_amount: changes.risk_amount,
                result: changes.result,
                odds: changes.odds,
              });
            }}
          />
        )}

        {mode === 'custom bet' && (
          <Row
            style={{
              margin: 0,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <input
              type="checkbox"
              id="add-to-public"
              name="add-to-public"
              onChange={input => {
                setBet({ ...bet, is_public: input.target.checked });
              }}
            />
            <label
              htmlFor="add-to-public"
              style={{ margin: '0 var(--space-xxxs)' }}
            >
              Add Bet to Public Profile
            </label>
          </Row>
        )}
        <AddEditTags
          existingTags={[]}
          onTagsChange={tags => setCommon({ ...common, tags: tags })}
        />
      </InputsWrapper>

      <Row
        style={{
          maxWidth: '700px',
          margin: 'var(--space-sm) auto',
        }}
      >
        <AuthButton
          colorTheme="danger"
          containerStyle={{
            flex: '1 0 300px',
            marginRight: 'var(--space-md)',
          }}
          onPress={() => props.hideModal()}
          disabled={isCreatingTransaction || isTrackingBets}
        >
          Cancel
        </AuthButton>
        <AuthButton
          containerStyle={{
            flex: '1 0 300px',
            marginLeft: 'var(--space-md)',
          }}
          disabled={mode === 'custom bet' ? betDisabled : transDisabled}
          isLoading={isCreatingTransaction || isTrackingBets}
          onPress={() => _place()}
        >
          {mode === 'custom bet' ? 'Track Custom Bet' : 'Add Transaction'}
        </AuthButton>
      </Row>
    </>
  );
}
