import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IoFileTrayOutline } from 'react-icons/io5';
import { GET_ADS } from 'actions';

//utils
import { roundOdds, toAmericanOdds } from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import { AuthButton, LinkButton } from 'components/AuthButton';
import BetSlipBet from 'components/BetSlipBet';
import BooksDropdown from 'components/BooksDropdown';
import { BetstampStampInverted } from 'components/generic/Logos';
import CurrencyInput from './generic/CurrencyInput';
import Switch from 'components/generic/Switch';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import DisplayToggle from 'components/generic/DisplayToggle';
import OddsInput from 'components/OddsInput';

// actions
import {
  getSched,
  removeAllBets,
  changeBetslipMode,
  riskBaseForAll,
  trackBetslip,
  setParlayValue,
  betslipChangeBookParlay,
  reuseBets,
  clearTrackedBets,
  editBetslipParlay,
} from 'actions';

const BetSlipWrapper = styled.div`
  flex: 0.2;
  min-width: 266px;
  max-width: 396px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-fg);
  box-sizing: border-box;
  overflow-y: hidden;
  opacity: ${props => {
    if (props.isTrackingBets) {
      return '0.5';
    }
    return '1';
  }};
  pointer-events: ${props => {
    if (props.isTrackingBets) {
      return 'none';
    }
    return 'auto';
  }};

  @media only screen and (max-width: 625px) {
    display: none;
  }
`;

const BetsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
`;

const Tab = styled.button`
  flex: 1;
  font-size: var(--text-sm);
  text-align: center;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);
  color: var(--text-color);
  box-sizing: border-box;
  padding: var(--space-sm) 0;
  line-height: 1;
  background: transparent;
  border: none;
  border-bottom: ${props => {
    if (props.selected) {
      return '2px solid var(--color-primary)';
    }
    return '2px solid transparent';
  }};

  opacity: ${props => {
    if (props.selected) {
      return '1';
    }
    return '0.75';
  }};

  cursor: pointer;

  &:hover {
    background-color: var(--color-bg);
  }
  &:active {
    opacity: 0.5;
    box-shadow: inset 0 0 0 100vh var(--color-bg);
  }
`;

export default function BetSlip(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    bets: state.betslipReducer.bets,
    mode: state.betslipReducer.mode,
    riskOrBaseOverride: state.betslipReducer.riskOrBaseOverride,
    betSizeOverride: state.betslipReducer.betSizeOverride,
    isTrackingBets: state.betslipReducer.isTrackingBets,
    trackingFailure: state.betslipReducer.trackingFailure,
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    parlayValues: state.betslipReducer.parlayValues,
    showingDate: state.scheduleReducer.showingDate,
    ads: state.adReducer.ads,
  }));
  const {
    bets,
    mode,
    isTrackingBets,
    parlayValues,
    user,
    showingDate,
    trackingFailure,
    riskOrBaseOverride,
    betSizeOverride,
  } = reduxProps;

  const odds_preference = user?.odds_preference;

  let actualRiskOrBase = null;
  if (riskOrBaseOverride) {
    actualRiskOrBase = riskOrBaseOverride;
  } else if (user && user.risk_or_base) {
    actualRiskOrBase = user.risk_or_base.toLowerCase();
  } else {
    actualRiskOrBase = 'base';
  }

  let actualBetSize = null;
  if (betSizeOverride !== null || betSizeOverride === '') {
    actualBetSize = betSizeOverride;
  } else if (user && user.bet_size) {
    actualBetSize = parseFloat(user.bet_size);
  } else {
    actualBetSize = 10;
  }

  let allBetsTracked = false;
  let anyBetZeroRisk = false;

  allBetsTracked = Boolean(bets.find(b => b.tracked));
  anyBetZeroRisk = Boolean(
    bets.find(
      b =>
        !b.risk_amount ||
        (b.changes?.risk_amount !== undefined && !b.changes?.risk_amount)
    )
  );

  let trackBtnDisabled = true;
  if (mode === 'singles') {
    trackBtnDisabled = allBetsTracked || anyBetZeroRisk || bets.length === 0;
  } else if (mode === 'parlay') {
    trackBtnDisabled =
      !parlayValues.isValid ||
      allBetsTracked ||
      anyBetZeroRisk ||
      bets.length < 2;
  }

  // clear tracked bets on mount so they go away when you leave the games screen and return
  useEffect(() => {
    dispatch({ type: GET_ADS });
    dispatch(clearTrackedBets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const betslipAd = reduxProps.ads ? reduxProps.ads.find(ad => ad.screen === 'WebAppBetslip') : null;

  return (
    <BetSlipWrapper isTrackingBets={isTrackingBets}>
      {
        betslipAd && betslipAd.generic_banner && betslipAd.link &&
        <a
          href={betslipAd.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={betslipAd.generic_banner}
            alt="Add Bet Form"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </a>
      }
      <Row style={{ flex: 0, width: '100%', alignItems: 'center' }}>
        <Tab
          selected={mode === 'singles'}
          onClick={() => {
            if (mode !== 'singles') {
              dispatch(changeBetslipMode('singles'));
            }
          }}
        >
          SINGLES
        </Tab>
        <Tab
          selected={mode === 'parlay'}
          onClick={() => {
            if (mode !== 'parlay') {
              let rob = user && user.risk_or_base ? user.risk_or_base : 'base';
              let bs = user && user.bet_size ? user.bet_size : 10;
              dispatch(changeBetslipMode('parlay', rob, bs));
            }
          }}
        >
          PARLAY
        </Tab>
      </Row>
      {mode === 'parlay' && (
        parlayValues.book && (
          <BooksDropdown
            styles={{
              container: base => ({
                ...base,
                width: '100%',
                padding: '0 var(--space-xxxs)',
                marginTop: 'var(--space-xs)',
                fontSize: '80%',
              }),
            }}
            value={{
              value: parlayValues.book.id,
              label: parlayValues.book.name,
            }}
            onChange={selectedOption => {
              dispatch(
                betslipChangeBookParlay({
                  id: selectedOption.value,
                  name: selectedOption.label,
                })
              );
              dispatch(
                getSched(
                  null,
                  showingDate,
                  {
                    id: selectedOption.value,
                    name: selectedOption.label,
                  },
                  null,
                  null
                )
              );
            }}
          />
        )
      )}

      {mode === 'parlay' && (
        <Row
          style={{
            flex: 0,
            width: '100%',
            marginTop: 'var(--space-xs)',
            boxSizing: 'border-box',
          }}
        >
          <Col
            style={{
              boxSizing: 'border-box',
              padding: '0 var(--space-xxxs)',
              marginRight: 'var(--space-sm)',
            }}
          >
            <small>Risk</small>
            <CurrencyInput
              commaSeparate
              inputStyle={{
                width: '100%',
                height: '30px',
                textAlign: 'center',
                marginRight: '12px',
              }}
              inputRowStyle={{
                backgroundColor: 'var(--color-bg)',
              }}
              defaultValue={parlayValues.risk_amount}
              onChange={dollars => {
                dispatch(setParlayValue('risk_amount', parseFloat(dollars)));
              }}
            />
          </Col>
          <Col
            style={{
              boxSizing: 'border-box',
              padding: '0 var(--space-xxxs)',
              marginLeft: 'var(--space-sm)',
            }}
          >
            <small>To Win</small>
            <CurrencyInput
              commaSeparate
              inputStyle={{
                width: '100%',
                height: '30px',
                textAlign: 'center',
                marginRight: '12px',
              }}
              inputRowStyle={{
                backgroundColor: 'var(--color-bg)',
              }}
              defaultValue={parlayValues.win}
              onChange={dollars => {
                dispatch(setParlayValue('win', parseFloat(dollars)));
              }}
            />
          </Col>
        </Row>
      )}

      {bets.length > 0 && mode === 'singles' && (
        <Row
          style={{
            flex: 0,
            width: '100%',
            alignItems: 'flex-end',
            margin: 'var(--space-xs) 0',
            padding: '0 var(--space-xxs)',
          }}
        >
          <CurrencyInput
            commaSeparate
            label={`${
              actualRiskOrBase[0].toUpperCase() + actualRiskOrBase.substr(1)
            } For All`}
            containerStyle={{ flex: 1, margin: 0, marginRight: '4px' }}
            inputStyle={{ width: '50%' }}
            inputRowStyle={{
              backgroundColor: 'var(--color-bg)',
            }}
            defaultValue={actualBetSize}
            onChange={dollars => {
              dispatch(riskBaseForAll(actualRiskOrBase, parseFloat(dollars)));
            }}
          />
          <DisplayToggle
            style={{
              flex: 1,
              backgroundColor: 'var(--color-bg)',
              marginLeft: '4px',
            }}
            options={['risk', 'base']}
            active={actualRiskOrBase}
            onPress={mode => {
              dispatch(riskBaseForAll(mode, actualBetSize));
            }}
          />
        </Row>
      )}
    {mode === 'parlay' && (
      <>
          <Row
          style={{
            flex: 0,
            width: '100%',
            // marginTop: 'var(--space-xs)',
            boxSizing: 'border-box',
            alignItems: 'baseline',
            gap: 'var(--space-xxs)',
            justifyContent: 'flex-start',
            alignItems: 'end',
            padding: '0 var(--space-xxxs)',
          }}
        >
               <Col
        style={{
          boxSizing: 'border-box',
          alignItems: 'center',
          minWidth: '110px',
          margin: '0',
        }}
      >
        <small>Odds</small>
        <OddsInput
            defaultValue={parlayValues.odds}
            verified_odds={parlayValues.original_odds || parlayValues.odds}
            oddsPreference={odds_preference}
            handleChange={(odds, whatChanged, isValid) => {
              if (!isValid) return;

              if (odds >= 2 || user.risk_or_base === 'RISK') {
                dispatch(
                  editBetslipParlay({ odds }, 'odds', 'result')
                );
              } else {
                dispatch(
                  editBetslipParlay({ odds }, 'odds', 'risk_amount')
                );
              }          
            }}             
            overrideInputRowStyle={{
              backgroundColor: 'var(--color-bg)',
            }}
            overrideInputStyle={{
              flexShrink: 0,
              width: '100%',
              height: '30px',
              textAlign: 'center',
              marginRight: '12px',
            }}
          />
      </Col>
          {Boolean(parlayValues.is_verified) && (
            <span
              style={{
                marginBottom: 'var(--space-xxxs)',
              }}
            >
                  <BetstampStampInverted
              title={'Bet Verified'}
              width={28}
              height={28}
            />
            </span>
          )}
        </Row>
    <Row
      style={{
        flex: 0,
        width: '100%',
        marginTop: 'var(--space-xs)',
        boxSizing: 'border-box',
        padding: '0 var(--space-xxs)',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 'var(--space-sm)',
      }}
    >
      <b>Public</b>
      <Switch
        defaultChecked={true}
        onChange={input =>
          dispatch(setParlayValue('is_public', input.target.checked))
        }
      />
    </Row>
  </>
)}

      <BetsWrapper>
        {bets.length === 0 && (
          <>
            <IoFileTrayOutline
              size="33%"
              style={{
                height: 'auto',
                marginLeft: '33%',
                marginTop: '75%',
                textAlign: 'center',
                opacity: 0.75,
              }}
            />
            <p
              style={{
                textAlign: 'center',
                width: '76%',
                margin: 'auto',
              }}
            >
              Your bet slip is empty!
              <br />
              Click the odds to add a bet
            </p>
          </>
        )}
        {bets.map((bet, i) => (
          <BetSlipBet
            key={`betslip-bet-${bet.localID}-${bet.tracked}-${bet.game?.id}-${bet.side}-${bet.book?.id}`}
            index={i}
            bet={bet}
            onClick={
              props.onClickBetslipBet
                ? () => props.onClickBetslipBet(bet.localID)
                : null
            }
          />
        ))}

        {allBetsTracked && bets.length > 0 && (
          <AuthButton
            btnTheme="borderless"
            onPress={() => dispatch(reuseBets())}
          >
            Retain Selections
          </AuthButton>
        )}

        {bets.length > 0 && (
          <AuthButton
            colorTheme="danger"
            btnTheme="borderless"
            onPress={() => {
              dispatch(removeAllBets());
              if (mode === 'parlay') {
                dispatch(changeBetslipMode('singles'));
              }
            }}
          >
            Remove All
          </AuthButton>
        )}
      </BetsWrapper>

      <Col
        style={{
          flex: 0,
          boxSizing: 'border-box',
          justifyContent: 'flex-end',
          paddingBottom: 'var(--space-xs)',
          width: '99%',
          borderTop: '1px solid var(--color-bg)',
          padding: 'var(--space-xs) var(--space-xxs)',
        }}
      >
        {trackingFailure && (
          <small style={{ textAlign: 'center' }}>
            <ErrorDisplay error={trackingFailure} basic />
          </small>
        )}

        {bets.length > 0 && (
          <LinkButton
            to="/edit-betslip"
            linkStyle={{
              width: '100%',
            }}
            btnTheme="borderless"
          >
            Edit Betslip
          </LinkButton>
        )}

        <AuthButton
          isLoading={isTrackingBets}
          disabled={trackBtnDisabled}
          onPress={() => dispatch(trackBetslip())}
        >
          Track
        </AuthButton>
      </Col>
    </BetSlipWrapper>
  );
}
