import React from 'react';
import Analytics from 'amplitude/Analytics';

export default function TOS(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'start',
      }}
    >
      <input
        type="checkbox"
        name="tc-pp"
        id="tc-pp"
        checked={props.acceptedTOS}
        onChange={input => props.setAcceptedTOS(!props.acceptedTOS)}
      />
      <label htmlFor="tc-pp" style={{ maxWidth: '500px', lineHeight: '1.1' }}>
        <small style={{ textAlign: 'center' }}>
          By signing up, you agree to the{' '}
          <a
            href="https://www.betstamp.app/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'cornflowerblue' }}
            onClick={() => {
              Analytics.track(Analytics.events.CLICKED_TOS);
            }}
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            style={{ color: 'cornflowerblue' }}
            href="https://www.betstamp.app/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          &nbsp;*
        </small>
      </label>
    </div>
  );
}
