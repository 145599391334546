import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useLocation, useHistory } from 'react-router-dom';
import { IoMailOutline, IoCall, IoText, IoImageOutline } from 'react-icons/io5';

// utils
import {
  isEmailValid,
  PROVINCE_OPTIONS,
  US_STATES,
  humanDatetime,
} from 'utils';

// components
import { AuthTextInput } from 'components/AuthTextInput';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthButton } from 'components/AuthButton';
import { Col, Row, Grid } from 'components/generic/Layout';
import Select from 'components/generic/Select';
import IntakeCreateLogin from 'components/onboarding/IntakeCreateLogin';
import AppStoreImg from 'images/download_app_store.png';
import PlayStoreImg from 'images/download_play_store.png';

// actions
import { loginUser } from 'actions';

//Intake form submitted & age is under 18 in Manitoba/Alberta/Quebec, Under 19 in Ontario/BC/NFLD/New Brunswick/Nova Scotia/PEI/Saskatchewan, Under 21 in US)
const AGE_PROVINCE = {
  Alberta: 18,
  'British Columbia': 19,
  Manitoba: 18,
  'New Brunswick': 19,
  'Newfoundland and Labrador': 19,
  'Nova Scotia': 19,
  'Northwest Territories': 19,
  Nunavut: 19,
  Ontario: 19,
  'Prince Edward Island': 19,
  Quebec: 18,
  Saskatchewan: 18,
  Yukon: 19,
};

export default function Intake() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    user: state.authReducer.user,
  }));
  const { userToken, user } = reduxProps;

  const history = useHistory();

  const params = new URLSearchParams(useLocation().search);
  const given_attendee_id = params.get('attendee_id');
  const given_session_id = params.get('session_id');
  const referral_code_code = params.get('referral_code');
  const source = params.get('source');

  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    region: params.get('region'),
    session_id: given_session_id,
    existing_books: [],
    referral_code_code: referral_code_code,
    source: source,
    country: 'Canada',
    not_special_person_checked: false,
    fit_to_play_checked: false,
    communication_checked: false,
  });

  const checkRefCodeExists = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!formData.referral_code_code,
    queryKey: [
      {
        endpoint: 'referral_codes/exists',
        urlParams: { code: formData.referral_code_code },
      },
    ],
  });

  const fetchAttendee = useQuery({
    enabled: !!given_attendee_id,
    refetchOnWindowFocus: false,
    //retry: false,
    queryKey: [
      { endpoint: `onboarding-attendees`, objectID: given_attendee_id },
    ],
    onSuccess: data => {
      const nameSplit = data?.attendee.full_name?.split(' ');
      let firstName = nameSplit[0];
      let lastName = data?.attendee.full_name;
      if (nameSplit.length > 1) {
        lastName = data?.attendee.full_name.split(' ')[1];
      }
      setFormData({
        ...data?.attendee,
        first_name: firstName,
        last_name: lastName,
      });
    },
    onError: err => history.replace('/intake'),
  });

  const fetchAvailableSessions = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-sessions',
        urlParams: { sort: JSON.stringify(['date']) },
      },
    ],
  });

  const fetchGivenSession = useQuery({
    enabled: !!given_session_id,
    refetchOnWindowFocus: false,
    queryKey: [{ endpoint: 'onboarding-sessions', objectID: given_session_id }],
  });

  const fetchBooksInRegion = useQuery({
    enabled: !!formData.region,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'books',
        urlParams: {
          states: JSON.stringify([formData.region]),
        },
      },
    ],
    select: data => {
      return {
        books: data.filter(b => !b.is_casino),
        casino: data.filter(b => b.is_casino),
      };
    },
  });

  const submitMut = useMutation({
    mutationKey: 'onboarding-submit-intake',
  });

  useEffect(() => {
    if (
      submitMut.isSuccess &&
      submitMut?.data?.betstamp_account_created &&
      !userToken
    ) {
      dispatch(
        loginUser({
          email: formData.betstamp_email,
          password: formData.password,
        })
      );
    }
  }, [submitMut.isSuccess]);

  if (submitMut.isSuccess) {
    return (
      <div
        style={{
          textAlign: 'center',
          margin: 'var(--space-xl) auto',
          maxWidth: '800px',
        }}
      >
        <h2>Submitted!</h2>
        <h4>
          An email has been sent you with an invitation to the session and your
          next steps.
        </h4>
        <h4>On the day of the session, please go to your unique link: </h4>
        <h4>
          <a
            href={`${window.location.origin}/session/${submitMut.data?.session_id}/${submitMut.data?.attendee_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {window.location.origin}/session/{submitMut.data?.session_id}/
            {submitMut.data?.attendee_id}
          </a>
        </h4>
        <h4>This link has also been emailed to you.</h4>
        <br />
        <h5>In the meantime, download the betstamp app for iOS or Android</h5>
        <Row style={{ marginTop: 'var(--space-md)', gap: 'var(--space-sm)' }}>
          <a
            href="https://apps.apple.com/us/app/id1525948689"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={AppStoreImg}
              alt="Download from the App Store"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=app.cashew.betstamp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={PlayStoreImg}
              alt="Download from the Play Store"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
        </Row>
      </div>
    );
  }

  if (given_attendee_id && fetchAttendee?.isLoading) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: 'var(--space-xxxl)' }} />
    );
  }

  let age = null;
  let ageError = null;
  if (formData?.bday_day && formData?.bday_month && formData?.bday_year) {
    age =
      new Date() -
      new Date(
        `${formData.bday_year}-${formData.bday_month}-${formData.bday_day}`
      ).getTime();

    age = age / 1000 / 60 / 60 / 24 / 365;

    if (formData?.country === 'USA' && age < 21) {
      ageError = 'You must be at least 21';
    } else if (formData?.region && age < AGE_PROVINCE[formData?.region]) {
      ageError = `You must be at least ${AGE_PROVINCE[formData?.region]}`;
    }
  }

  let formValid =
    formData.first_name &&
    formData.last_name &&
    formData.email &&
    !formErrors.email &&
    formData.payment_email &&
    formData.bday_year &&
    !isNaN(formData.bday_year) &&
    formData.bday_month &&
    formData.bday_day &&
    !isNaN(formData.bday_day) &&
    formData.session_id &&
    formData.phone_number &&
    formData.photo_id &&
    formData.region &&
    formData.not_special_person_checked &&
    formData.fit_to_play_checked &&
    formData.communication_checked &&
    !ageError &&
    (user?.id ||
      (formData.newAccountValid &&
        formData.emailIsAvailable === '' &&
        formData.usernameIsAvailable === '')) &&
    (!formData.referral_code_code ||
      (formData.referral_code_code && checkRefCodeExists?.data?.exists));
  if (given_attendee_id) {
    formValid = formData.session_id;
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 var(--space-xxs)',
        paddingBottom: 'var(--space-xl)',
      }}
    >
      <h2>{given_attendee_id ? 'Reschedule Session' : 'Session Sign Up'}</h2>
      <form
        onSubmit={ev => {
          ev.preventDefault();
          const fd = new FormData(ev.target);
          if (!given_attendee_id) {
            fd.append(
              'date_of_birth',
              `${fd.get('bday_year')}-${fd.get('bday_month')}-${fd.get(
                'bday_day'
              )}`
            );
            if (formData.referral_code_code) {
              fd.append('referral_code_code', formData.referral_code_code);
            }
            if (formData.source) {
              fd.append('source', formData.source);
            }
            fd.append('existing_books', formData.existing_books);
          }
          if (given_session_id) {
            fd.append('session_id', given_session_id);
          }
          if (user) {
            fd.append('betstamp_user_id', user.id);
          }
          if (given_attendee_id) {
            fd.append('given_attendee_id', given_attendee_id);
            for (const [key, value] of Object.entries(formData)) {
              fd.append(key, value);
            }
          }
          if (formData.create_betstamp_account) {
            fd.append('create_betstamp_account', true);
          }
          submitMut.mutate({
            endpoint: `onboarding-submit-intake`,
            body: fd,
          });
        }}
      >
        {!given_attendee_id && (
          <>
            <Row style={{ gap: 'var(--space-sm)' }}>
              <AuthTextInput
                autoFocus
                containerStyle={{ flex: 1 }}
                label="First Name *"
                id="first-name"
                name="first_name"
                placeholder="Jack"
                onChangeText={text => {
                  setFormData({ ...formData, first_name: text });
                  if (formErrors.first_name) {
                    setFormErrors({ ...formErrors, first_name: !text });
                  }
                }}
                errorText={
                  formErrors.first_name ? 'You must include a first name' : null
                }
                onEndEditing={input => {
                  setFormErrors({
                    ...formErrors,
                    first_name: !input.target.value,
                  });
                }}
                autoComplete="given-name"
              />
              <AuthTextInput
                containerStyle={{ flex: 1 }}
                label="Last Name *"
                id="last-name"
                name="last_name"
                placeholder="Smith"
                onChangeText={text => {
                  setFormData({ ...formData, last_name: text });
                  if (formErrors.last_name) {
                    setFormErrors({ ...formErrors, last_name: !text });
                  }
                }}
                errorText={
                  formErrors.last_name ? 'You must include a last name' : null
                }
                onEndEditing={input => {
                  setFormErrors({
                    ...formErrors,
                    last_name: !input.target.value,
                  });
                }}
                autoComplete="family-name"
              />
            </Row>
            <Row style={{ gap: 'var(--space-sm)' }}>
              <AuthTextInput
                label="Email *"
                leftIcon={IoMailOutline}
                id="email"
                name="email"
                autoComplete="email"
                containerStyle={{ flex: 1 }}
                placeholder="jacksmith@gmail.com"
                errorText={formErrors.email ? 'This email is not valid' : null}
                onEndEditing={input => {
                  setFormErrors({
                    ...formErrors,
                    email: !isEmailValid(input.target.value),
                  });
                }}
                onChangeText={text => {
                  if (text.includes('@') && text.includes('.')) {
                    setFormErrors({
                      ...formErrors,
                      email: !isEmailValid(text),
                    });
                  }
                  setFormData({
                    ...formData,
                    email: text,
                    payment_email: text,
                  });
                }}
              />
              <AuthTextInput
                label="PayPal/e-Transfer Email"
                id="payment_email"
                leftIcon={IoMailOutline}
                name="payment_email"
                containerStyle={{ flex: 1 }}
                placeholder="jacksmith@gmail.com"
                autoComplete="email"
                errorText={
                  formErrors.payment_email ? 'This email is not valid' : null
                }
                onEndEditing={input => {
                  setFormErrors({
                    ...formErrors,
                    payment_email: !isEmailValid(input.target.value),
                  });
                }}
                onChangeText={text => {
                  if (text.includes('@') && text.includes('.')) {
                    setFormErrors({
                      ...formErrors,
                      payment_email: !isEmailValid(text),
                    });
                  }
                  setFormData({ ...formData, payment_email: text });
                }}
                value={formData.payment_email || ''}
              />
            </Row>
            <AuthTextInput
              label="Phone Number *"
              id="tel"
              leftIcon={IoCall}
              name="phone_number"
              containerStyle={{ flex: 1, maxWidth: '800px' }}
              placeholder="647 555 2933"
              autoComplete="tel"
              maxLength={16}
              errorText={
                formErrors.phone_number
                  ? 'You must include a phone number'
                  : null
              }
              onChangeText={text => {
                setFormData({ ...formData, phone_number: text });
                if (formErrors.last_name) {
                  setFormErrors({ ...formErrors, phone_number: !text });
                }
              }}
              onEndEditing={input => {
                setFormErrors({
                  ...formErrors,
                  phone_number: !input.target.value,
                });
              }}
            />

            <Row style={{ gap: '1rem' }}>
              <Col style={{ flex: '1 0 300px' }}>
                <Select
                  label="Country"
                  id="country"
                  name="country"
                  options={[
                    { label: 'Canada', value: 'Canada' },
                    { label: 'USA', value: 'USA' },
                  ]}
                  onChange={opt => {
                    setFormData({ ...formData, country: opt.value });
                  }}
                  defaultValue={
                    formData.country
                      ? { label: formData.country, value: formData.country }
                      : undefined
                  }
                />
              </Col>
              <Col style={{ flex: '2 0 300px' }}>
                <Select
                  label={
                    formData.country === 'USA'
                      ? 'State of Residence'
                      : 'Province of Residence'
                  }
                  id="region"
                  name="region"
                  options={
                    formData.country === 'Canada'
                      ? PROVINCE_OPTIONS.map(p => ({ label: p, value: p }))
                      : US_STATES.map(s => ({ label: s, value: s }))
                  }
                  onChange={opt => {
                    setFormData({ ...formData, region: opt.value });
                  }}
                  defaultValue={
                    formData.region
                      ? { label: formData.region, value: formData.region }
                      : undefined
                  }
                />
              </Col>
            </Row>

            <br />
            <span>Date of Birth *</span>
            <Row
              style={{
                alignItems: 'center',
                gap: 'var(--space-sm)',
                marginBottom: 'var(--space-md)',
              }}
            >
              <Col style={{ flex: '1 0 150px', alignItems: 'flex-start' }}>
                <Select
                  label="Month"
                  labelStyle={{ margin: 0 }}
                  id="bday_month"
                  name="bday_month"
                  containerStyle={{ flex: 1 }}
                  options={[
                    { label: 'January', value: '01' },
                    { label: 'Feburary', value: '02' },
                    { label: 'March', value: '03' },
                    { label: 'April', value: '04' },
                    { label: 'May', value: '05' },
                    { label: 'June', value: '06' },
                    { label: 'July', value: '07' },
                    { label: 'August', value: '08' },
                    { label: 'September', value: '09' },
                    { label: 'October', value: '10' },
                    { label: 'November', value: '11' },
                    { label: 'December', value: '12' },
                  ]}
                  onChange={opt => {
                    setFormData({ ...formData, bday_month: opt.value });
                  }}
                  autoComplete="bday-month"
                />
              </Col>
              <AuthTextInput
                step={1}
                max={31}
                min={1}
                name="bday_day"
                label="Day (ex. 23)"
                maxLength={2}
                placeholder="10"
                containerStyle={{ flex: 1, margin: 0 }}
                onChangeText={text => {
                  let d = parseInt(text);

                  setFormData({ ...formData, bday_day: text });
                  if (isNaN(d)) {
                    setFormErrors({
                      ...formErrors,
                      bday_day: true,
                      bday_day_text: 'Must be a number',
                    });
                    return;
                  }

                  if (d > 31 || d < 1) {
                    setFormErrors({
                      ...formErrors,
                      bday_day: true,
                      bday_day_text: 'Must be between 1 and 31',
                    });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      bday_day: false,
                      bday_day_text: null,
                    });
                  }
                }}
                errorText={
                  formErrors.bday_day ? formErrors.bday_day_text : null
                }
                autoComplete="bday-day"
              />
              <AuthTextInput
                name="bday_year"
                label="Year (ex. 1994)"
                maxLength={4}
                max={2023}
                min={1900}
                placeholder="1983"
                containerStyle={{ flex: 2, margin: 0 }}
                onChangeText={text => {
                  let d = parseInt(text);

                  setFormData({ ...formData, bday_year: text });
                  if (isNaN(d)) {
                    setFormErrors({
                      ...formErrors,
                      bday_year: true,
                      bday_year_text: 'Must be a number',
                    });
                    return;
                  }

                  if (d > 2023 || d < 1900) {
                    setFormErrors({
                      ...formErrors,
                      bday_year: true,
                      bday_year_text: 'Must be between 1900 and 2023',
                    });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      bday_year: false,
                      bday_year_text: null,
                    });
                  }
                }}
                errorText={
                  formErrors.bday_year || ageError
                    ? formErrors.bday_year_text || ageError
                    : null
                }
                autoComplete="bday-year"
              />
            </Row>

            <AuthTextInput
              label="Government Issued Photo ID*"
              id="photo-id"
              leftIcon={IoImageOutline}
              name="photo_id"
              type="file"
              errorText={!!formErrors.photo_id ? formErrors.photo_id : null}
              onChangeText={(text, input) => {
                if (input.target.files?.length > 0) {
                  if (input.target.files[0].size / 1_000_000 >= 10) {
                    setFormErrors({
                      ...formErrors,
                      photo_id: 'Photo ID must be less than 10mb',
                    });
                    return;
                  }
                  if (
                    ['image/jpg', 'image/jpeg', 'image/png'].includes(
                      input.target.files[0].type
                    )
                  ) {
                    setFormData({ ...formData, photo_id: input.target });
                    setFormErrors({
                      ...formErrors,
                      photo_id: null,
                    });
                  } else {
                    setFormData({ ...formData, photo_id: null });
                    setFormErrors({
                      ...formErrors,
                      photo_id: 'Must upload a jpg or png image',
                    });
                  }
                }
              }}
              containerStyle={{ maxWidth: '400px' }}
              accept="image/png, image/jpeg"
            />
            {source !== 'bacanuni' && (
              <>
                <h4>Promo Code</h4>
                {referral_code_code ? (
                  <p>{referral_code_code}</p>
                ) : (
                  <AuthTextInput
                    label="If an existing Brand Ambassador or Influencer referred you into the program, please type their promo code below"
                    containerStyle={{ maxWidth: '900px' }}
                    id="referral_code_code"
                    leftIcon={IoText}
                    name="referral_code_code"
                    placeholder="CODE"
                    value={formData.referral_code_code || ''}
                    errorText={
                      checkRefCodeExists?.data?.exists ||
                      !formData.referral_code_code
                        ? null
                        : 'Referral code does not exist'
                    }
                    onChangeText={text => {
                      setFormData({
                        ...formData,
                        referral_code_code: text.toUpperCase(),
                      });
                    }}
                  />
                )}
              </>
            )}
            {!!formData.region && fetchBooksInRegion.data ? (
              <>
                {fetchBooksInRegion?.data?.books && (
                  <>
                    <h4>Existing Sportsbook Accounts</h4>
                    <p>Select all sportbooks you already have an account at</p>
                    <Grid style={{ gridGap: 'var(--space-xs)' }}>
                      {fetchBooksInRegion?.data?.books?.map(book => (
                        <ExistingSB
                          key={`affiliate-existing-book-${book.id}`}
                          book={book}
                          formData={formData}
                          setFormData={setFormData}
                        />
                      ))}
                    </Grid>
                  </>
                )}
                {fetchBooksInRegion?.data?.casino && (
                  <>
                    <h4>Existing Casino Accounts</h4>
                    <p>Select all casino you already have an account at</p>
                    <Grid style={{ gridGap: 'var(--space-xs)' }}>
                      {fetchBooksInRegion?.data?.casino?.map(book => (
                        <ExistingSB
                          key={`affiliate-existing-casino-${book.id}`}
                          book={book}
                          formData={formData}
                          setFormData={setFormData}
                        />
                      ))}
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <p style={{ marginTop: 'var(--space-xl)' }}>
                Select a region to continue
              </p>
            )}

            <IntakeCreateLogin
              givenEmail={formData.email}
              setNewAccount={newUserData => {
                setFormData({
                  ...formData,
                  ...newUserData,
                  create_betstamp_account: true,
                });
              }}
            />
          </>
        )}

        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            alignItems: 'start',
          }}
        >
          <input
            type="checkbox"
            name="not_special_person_checked"
            id="not_special_person_checked"
            onChange={ev => {
              setFormData({
                ...formData,
                not_special_person_checked: ev.target.checked,
              });
            }}
          />
          <label
            htmlFor="not_special_person_checked"
            style={{ maxWidth: '500px', lineHeight: '1.1' }}
          >
            <small>
              By checking this box, you acknowledge that you are not a
              Politically Exposed Person (PEP) or Head of an International
              Organization (HIO) or employee of a Partner Organization /
              Conflict of Interest Business (COI) in the Sports Betting space,
              or a family member or close associate of a PEP/HIO/COI. *
            </small>
          </label>
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            alignItems: 'start',
          }}
        >
          <input
            type="checkbox"
            name="fit_to_play_checked"
            id="fit_to_play_checked"
            onChange={ev => {
              setFormData({
                ...formData,
                fit_to_play_checked: ev.target.checked,
              });
            }}
          />
          <label
            htmlFor="fit_to_play_checked"
            style={{ maxWidth: '500px', lineHeight: '1.1' }}
          >
            <small>
              By checking this box, you acknowledge that you are fit to play in
              the region you are currently located in - meaning you are above
              the age of majority, you are not on any self-exclusion lists. *
            </small>
          </label>
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            alignItems: 'start',
          }}
        >
          <input
            type="checkbox"
            name="communication_checked"
            id="communication_checked"
            onChange={ev => {
              setFormData({
                ...formData,
                communication_checked: ev.target.checked,
              });
            }}
          />
          <label
            htmlFor="communication_checked"
            style={{ maxWidth: '500px', lineHeight: '1.1' }}
          >
            <small>
              By checking this box, you consent to receive email and text
              communications from our team regarding your role, project updates,
              and relevant opportunities—including future promotions, events, or
              roles in your region. You can unsubscribe at any time. *
            </small>
          </label>
        </div>

        <h4>Book a Session</h4>
        {given_session_id ? (
          <>
            {fetchGivenSession.isLoading ? (
              <ActivityIndicator />
            ) : (
              <>
                {fetchGivenSession.isError ? (
                  <p style={{ color: 'var(--color-danger)' }}>
                    {fetchGivenSession?.error?.message}
                  </p>
                ) : (
                  <p>
                    You are pre-booked for a session on{' '}
                    {humanDatetime(
                      fetchGivenSession?.data?.onboarding_session?.date,
                      false,
                      true,
                      true
                    )}
                  </p>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {!fetchAvailableSessions.isLoading &&
              fetchAvailableSessions.data?.results?.length === 0 && (
                <p>There are no sessions available at this time</p>
              )}
            {fetchAvailableSessions.isSuccess && (
              <Grid style={{ gap: 'var(--space-xs)' }}>
                {fetchAvailableSessions.data.results.map(sess => (
                  <div
                    key={`session-${sess.id}`}
                    style={{
                      flex: 1,
                      borderRadius: 'var(--std-border-radius)',
                      justifyContent: 'stretch',
                      border: `1px solid ${
                        formData.session_id === sess.id
                          ? 'var(--color-primary)'
                          : 'var(--color-text-light)'
                      }`,
                    }}
                  >
                    <input
                      style={{ display: 'none' }}
                      type="radio"
                      id={`session_id-${sess.id}`}
                      name="session_id"
                      value={sess.id}
                      onChange={input => {
                        setFormData({ ...formData, session_id: sess.id });
                      }}
                    />
                    <label
                      htmlFor={`session_id-${sess.id}`}
                      style={{
                        width: '100%',
                        display: 'inline-block',
                        padding: 'var(--space-md)',
                        cursor: 'pointer',
                        fontWeight:
                          formData.session_id === sess.id ? 'bold' : 'normal',
                      }}
                    >
                      {humanDatetime(sess.date, false, true, true)}
                    </label>
                  </div>
                ))}
              </Grid>
            )}
          </>
        )}

        <br />
        <AuthButton
          type="submit"
          containerStyle={{ maxWidth: '400px' }}
          disabled={
            !formValid ||
            (given_session_id &&
              (fetchGivenSession.isError || fetchGivenSession.isLoading))
          }
          isLoading={submitMut.isLoading}
        >
          Schedule Session
        </AuthButton>
      </form>
      <br />
      {formData?.region === 'Ontario' && (
        <p>
          19+ Gambling can be addictive, please play responsibly. Visit{' '}
          <a href="https://www.connexontario.ca">ConnexOntario.ca</a> or call
          1-800-531-2600 for responsible gambling information.
        </p>
      )}
      {formData?.region !== 'Ontario' &&
        PROVINCE_OPTIONS.includes(formData?.region) && (
          <p>
            18+ Gambling can be addictive, please play responsibly. Visit{' '}
            <a href="https://www.connexontario.ca">ConnexOntario.ca</a> or call
            1-800-531-2600 for responsible gambling information.
          </p>
        )}

      {(!formData?.region || US_STATES.includes(formData?.region)) && (
        <p>
          21+ Gambling can be addictive, please play responsibly. If you or
          someone you know has a gambling problem and wants help, please call
          1-800 GAMBLERS in the U.S.
        </p>
      )}
      {submitMut.isError && (
        <p style={{ color: 'var(--color-danger)' }}>
          {submitMut.error.message}
        </p>
      )}
    </div>
  );
}

function ExistingSB({ book, formData, setFormData }) {
  return (
    <AuthButton
      key={`affiliate-existing-book-${book.id}`}
      type="button"
      containerStyle={{ margin: 0, borderRadius: '4px' }}
      btnStyle={{ borderRadius: '4px' }}
      btnTheme="borderless"
      colorTheme={
        formData.existing_books.includes(book.name) ? 'inverted' : 'text'
      }
      onPress={() => {
        let eb = Array.from(formData.existing_books);
        if (formData.existing_books.includes(book.name)) {
          eb.splice(formData.existing_books.indexOf(book.name), 1);
        } else {
          eb.push(book.name);
        }
        setFormData({ ...formData, existing_books: eb });
      }}
      overrideChildren
    >
      <img
        src={book.generic_banner}
        alt={book.name}
        style={{
          height: 'auto',
          width: '100%',
          resizeMode: 'cover',
          borderRadius: '4px',
          opacity: formData.existing_books.includes(book.name) ? 0.6 : 1,
        }}
      />
    </AuthButton>
  );
}
