import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  IoChevronForward,
  IoChevronBack,
  IoRefresh,
  IoTodayOutline,
  IoPencilSharp,
} from 'react-icons/io5';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import {
  BsLayoutSidebarInsetReverse,
  BsLayoutSidebarReverse,
} from 'react-icons/bs';

// utils
import { LEAGUES } from 'utils';

// components
import GameListItem from 'components/GameListItem';
import { Row, Col } from 'components/generic/Layout';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import SearchBarGames from 'components/SearchBarGames';
import {
  Toolbar,
  MainAreaWrapper,
  InnerMainArea,
} from 'components/generic/Layout';
import ContentLoader from 'components/generic/ContentLoader';
import BooksDropdown from 'components/BooksDropdown';
import BetSlip from 'components/BetSlip';
import CalendarInput from 'components/generic/CalendarInput';
import FAB from 'components/generic/FAB';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import LeagueSelector from 'components/LeagueSelector';
import { AuthButton, IconButton } from 'components/AuthButton';

// actions
import { getSched, getSchedNext } from 'actions';

const SubToolbarRow = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  box-sizing: border-box;
  flex: 0;
  width: 100%;
  padding: 0 var(--space-xs);
  display: ${props => {
    if (props.isLoading) {
      return 'none';
    }
    return 'flex';
  }};
  position: sticky;
  top: 0;
  background-color: var(--color-bg);
  z-index: 100;
`;

const ColHideSmallScreen = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  background-color: var(--color-bg);
  @media only screen and (max-width: 1220px) {
    display: none;
  }
`;

const LargeSearchBarRow = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  height: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid var(--color-text-light);
  border-radius: 25px;
  padding: 0 var(--space-sm);
  box-sizing: border-box;

  @media only screen and (max-width: 1220px) {
    display: none;
  }
`;

const SmallSearchBarRow = styled.div`
  flex: 1;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: var(--space-xxxs) var(--space-xs);
  margin-bottom: var(--space-xs);
  box-sizing: border-box;
  display: none;
  background: var(--color-fg);
  @media only screen and (max-width: 1220px) {
    display: flex;
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 var(--space-xxs);
  padding-bottom: var(--space-xl);
`;

export default function GamesDesktop(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    selectedBook: state.scheduleReducer.selectedBook,
    selectedLeagues: state.scheduleReducer.selectedLeagues,
    showingDate: state.scheduleReducer.showingDate,
    fetchSchedError: state.scheduleReducer.fetchSchedError,
    schedNext: state.scheduleReducer.schedNext,
    schedule: state.scheduleReducer.schedule,
    searchterm: state.scheduleReducer.searchterm,
    defaultGameFilter: state.settingsReducer.defaultGameFilter,
    gameFilter: state.settingsReducer.gameFilter,
    mode: state.betslipReducer.mode,
    parlayValues: state.betslipReducer.parlayValues,
    refreshingAll: state.scheduleReducer.refreshingAll,
    isSearchingOrFiltering: state.scheduleReducer.isSearchingOrFiltering,
    bets: state.betslipReducer.bets,
  }));

  const {
    selectedBook,
    selectedLeagues,
    showingDate,
    schedule,
    fetchSchedError,
    searchterm,
    defaultGameFilter,
    gameFilter,
    mode,
    parlayValues,
    refreshingAll,
    isSearchingOrFiltering,
    bets,
  } = reduxProps;

  const currGameFilter = gameFilter ? gameFilter : defaultGameFilter;

  let formattedPrevDate,
    formattedShowingDate,
    formattedNextDate = '';

  let scheduleForDate = undefined;
  if (schedule[showingDate.substr(0, 10)]) {
    scheduleForDate = schedule[showingDate.substr(0, 10)];
  }

  formattedShowingDate = moment(showingDate).format('ddd MMM D');
  if (scheduleForDate) {
    formattedPrevDate = moment(scheduleForDate.prevEventDate).format(
      'dddd MMMM D'
    );
    formattedNextDate = moment(scheduleForDate.nextEventDate).format(
      'dddd MMMM D'
    );
  } else {
    scheduleForDate = {
      prevEventDate: null,
      nextEventDate: null,
      games: [],
      isLoading: true,
    };
  }

  const [calendarOpen, setCalendarOpen] = useState(false);

  let noGamesFor = [];
  if (scheduleForDate.games.length > 0) {
    let leagues =
      selectedLeagues.length === 0
        ? LEAGUES.map(l => l.league)
        : selectedLeagues;
    for (let i = 0; i < leagues.length; i++) {
      let matchingGame = scheduleForDate.games.find(
        g => g.league === leagues[i]
      );
      if (!matchingGame) noGamesFor.push(leagues[i]);
    }
  }

  useEffect(() => {
    document
      .getElementById('infinite-scroll-target-games')
      .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [searchterm]);

  const [showBetslip, setShowBetslip] = useState(true);

  let games = scheduleForDate.games;
  if (currGameFilter === 'games_with_odds') {
    games = games.filter(game => _hasOdds(game));
  }

  return (
    <>
      {!showBetslip && bets.length > 0 && (
        <Link to="/edit-betslip">
          <FAB style={{ width: '174px' }} onClick={() => null}>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
              <IoPencilSharp
                size={22}
                color="white"
                style={{ padding: '0 var(--space-xs)' }}
              />
              <span style={{ fontSize: 'var(--text-base-size)' }}>
                Edit Betslip ({bets.length})
              </span>
            </Row>
          </FAB>
        </Link>
      )}

      <Toolbar>
        <LargeSearchBarRow style={{ border: 'none' }}>
          <SearchBarGames schedule={true} />
        </LargeSearchBarRow>
        <LeagueSelector schedule={true} />
        {showBetslip ? (
          <div style={{ position: 'relative', height: '100%' }}>
            <IconButton
              iconName={BsLayoutSidebarInsetReverse}
              iconSize={22}
              iconTitle="Collapse betslip"
              containerStyle={{
                height: '100%',
                padding: '0 var(--space-sm)',
                borderLeft: '1px solid var(--color-text)',
                display: 'flex',
                alignItems: 'center',
              }}
              iconColor="var(--color-text)"
              onPress={() => setShowBetslip(false)}
            />
            {bets.length > 0 && (
              <span
                style={{
                  height: '18px',
                  width: '18px',
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  background: 'var(--color-danger)',
                  color: 'white',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  fontSize: '14px',
                }}
              >
                {bets.length}
              </span>
            )}
          </div>
        ) : (
          <div style={{ position: 'relative', height: '100%' }}>
            <IconButton
              iconName={BsLayoutSidebarReverse}
              iconSize={22}
              iconTitle="Expand betslip"
              containerStyle={{
                height: '100%',
                padding: '0 var(--space-sm)',
                borderLeft: '1px solid var(--color-text)',
                display: 'flex',
                alignItems: 'center',
              }}
              iconColor="var(--color-text)"
              onPress={() => setShowBetslip(true)}
            />
            {bets.length > 0 && (
              <span
                style={{
                  height: '18px',
                  width: '18px',
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  background: 'var(--color-danger)',
                  color: 'white',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  fontSize: '14px',
                }}
              >
                {bets.length}
              </span>
            )}
          </div>
        )}
      </Toolbar>
      <MainAreaWrapper
        style={isSearchingOrFiltering ? { pointerEvents: 'none' } : {}}
      >
        <InnerMainArea>
          {fetchSchedError && (
            <ErrorDisplay
              error={fetchSchedError}
              message={'getting the schedule.'}
            />
          )}

          <SmallSearchBarRow>
            <SearchBarGames schedule={true} />
          </SmallSearchBarRow>

          <ScrollContainer id="infinite-scroll-target-games">
            <SubToolbarRow isLoading={scheduleForDate.isLoading}>
              <Col style={{ flex: 1.1, alignItems: 'flex-start' }}>
                <Row style={{ width: '100%' }}>
                  <Col style={{ flex: 0.2 }}>
                    <IconButton
                      iconName={IoTodayOutline}
                      colorTheme="text"
                      onPress={() =>
                        dispatch(
                          getSched(
                            null,
                            moment().toISOString(true),
                            null,
                            null,
                            null,
                            true,
                            null,
                            true // reset
                          )
                        )
                      }
                      title={'Go to today'}
                    />
                  </Col>
                  <Col style={{ flex: 0.3 }}>
                    {scheduleForDate.prevEventDate && (
                      <IconButton
                        iconName={IoChevronBack}
                        colorTheme="text"
                        onPress={
                          scheduleForDate.prevEventDate
                            ? () =>
                                dispatch(
                                  getSched(null, scheduleForDate.prevEventDate)
                                )
                            : null
                        }
                        title={
                          scheduleForDate.prevEventDate
                            ? formattedPrevDate
                            : 'No More Events'
                        }
                        disabled={!scheduleForDate.prevEventDate}
                      />
                    )}
                  </Col>
                  <Col style={{ flex: 0.6 }}>
                    <Row style={{ alignItems: 'center' }}>
                      <AuthButton
                        colorTheme="text"
                        btnTheme="borderless"
                        containerStyle={{ margin: 0 }}
                        onPress={() => setCalendarOpen(!calendarOpen)}
                        textStyle={{ fontSize: 'var(--text-md)' }}
                      >
                        {formattedShowingDate}
                      </AuthButton>
                      <CalendarInput
                        extraClassNames={['hide-calendar-inputs']}
                        onChange={date => {
                          setCalendarOpen(false);
                          dispatch(
                            getSched(
                              null,
                              moment(date).toISOString(true),
                              null,
                              null,
                              null,
                              null,
                              true // exact
                            )
                          );
                        }}
                        isOpen={calendarOpen}
                      />
                    </Row>
                  </Col>
                  <Col style={{ flex: 0.3 }}>
                    {scheduleForDate.nextEventDate && (
                      <IconButton
                        iconName={IoChevronForward}
                        colorTheme="text"
                        onPress={
                          scheduleForDate.nextEventDate
                            ? () =>
                                dispatch(
                                  getSched(null, scheduleForDate.nextEventDate)
                                )
                            : null
                        }
                        title={
                          scheduleForDate.nextEventDate
                            ? formattedNextDate
                            : 'No More Events'
                        }
                        disabled={!scheduleForDate.nextEventDate}
                      />
                    )}
                  </Col>
                  <Col style={{ flex: 0.8, alignItems: 'flex-start' }}>
                    <AuthButton
                      overrideChildren
                      colorTheme="text"
                      btnTheme="borderless"
                      leftIcon={refreshingAll ? null : IoRefresh}
                      containerStyle={{ padding: '0 var(--space-md)' }}
                      onPress={() =>
                        dispatch(
                          getSched(
                            null, // leagues
                            showingDate, // date
                            null, // book
                            null, // search term
                            false, // preload
                            true // refresh
                          )
                        )
                      }
                      title={`Refresh Odds`}
                      isLoading={refreshingAll}
                    >
                      <small>
                        Last Refresh
                        <br />
                        {scheduleForDate?.cacheMetaData?.cachedAt.format(
                          'h:mm:ssa'
                        )}
                      </small>
                    </AuthButton>
                  </Col>
                </Row>
              </Col>
              <ColHideSmallScreen
                style={{ flex: 0.65, alignItems: 'flex-start' }}
              >
                <b>Best Available Fulltime</b>
              </ColHideSmallScreen>
              <ColHideSmallScreen>
                {mode === 'singles' && (
                  <BooksDropdown
                    styles={{
                      container: base => ({
                        ...base,
                        width: '75%',
                      }),
                    }}
                    value={{
                      value: selectedBook.id,
                      label: selectedBook.name,
                    }}
                    onChange={selectedOption => {
                      dispatch(
                        getSched(
                          null,
                          showingDate,
                          {
                            id: selectedOption.value,
                            name: selectedOption.label,
                          },
                          null,
                          null
                        )
                      );
                    }}
                    additionalOptions={[
                      { label: `Best Available 1st Half`, value: -1 },
                      // { label: 'Live Odds', value: -2 },
                      { label: 'Best Available Fulltime', value: -3 },
                    ]}
                  />
                )}
                {mode === 'parlay' && (
                  <b>{parlayValues.book ? parlayValues.book.name : ''}</b>
                )}
              </ColHideSmallScreen>
              {/* <ColHideSmallScreen style={{ flex: 0.125 }} /> */}
            </SubToolbarRow>

            {(isSearchingOrFiltering || scheduleForDate.isLoading) &&
              fetchSchedError === null && (
                <>
                  <br />
                  <br />
                  <ContentLoader height={'97px'} speed={'2s'} />
                  <br />
                  <ContentLoader height={'97px'} speed={'2s'} />
                  <br />
                  <ContentLoader height={'97px'} speed={'2s'} />
                  <br />
                  <ContentLoader height={'97px'} speed={'2s'} />
                  <br />
                  <ContentLoader height={'97px'} speed={'2s'} />
                  <br />
                  <ContentLoader height={'97px'} speed={'2s'} />
                  <br />
                  <ContentLoader height={'97px'} speed={'2s'} />
                </>
              )}

            <InfiniteScroll
              style={{ padding: 'var(--space-xs) 0' }}
              scrollableTarget={'infinite-scroll-target-games'}
              scrollThreshold={0.5}
              next={
                scheduleForDate.next ? () => dispatch(getSchedNext()) : null
              }
              hasMore={scheduleForDate.next !== null}
              dataLength={scheduleForDate.games.length}
              loader={<ContentLoader height={'145px'} speed={'2s'} />}
            >
              {!scheduleForDate.isLoading &&
                scheduleForDate.games.length === 0 && (
                  <h6
                    style={{
                      textAlign: 'center',
                      marginTop: 'var(--space-sm)',
                    }}
                  >
                    {searchterm !== ''
                      ? `No results for:  "${searchterm}"`
                      : `No ${
                          currGameFilter === 'all_games'
                            ? ''
                            : currGameFilter === 'only_unstarted_games'
                            ? 'unstarted '
                            : 'live '
                        }games on this day`}
                  </h6>
                )}
              {games.map((game, i) => (
                <React.Fragment key={`game-list-${game.id}-${i}`}>
                  {(selectedLeagues.length > 1 ||
                    selectedLeagues.length === 0) && (
                    <LeagueSeparator
                      game={game}
                      i={i}
                      scheduleForDate={scheduleForDate}
                    />
                  )}
                  <GameListItem game={game} />
                </React.Fragment>
              ))}

              {noGamesFor.map((ngf, i) => (
                <React.Fragment key={`game-list-league-separtor-${ngf}-${i}`}>
                  <LeagueSeparator i={0} game={{ league: ngf }} />
                  <h6 style={{ textAlign: 'center' }}>
                    {searchterm !== ''
                      ? `No results for:  "${searchterm}" in ${ngf}`
                      : `No ${
                          currGameFilter === 'all_games'
                            ? ''
                            : currGameFilter === 'only_unstarted_games'
                            ? 'unstarted '
                            : 'live '
                        }${ngf} games on this day`}
                  </h6>
                  <br />
                </React.Fragment>
              ))}
            </InfiniteScroll>
          </ScrollContainer>
        </InnerMainArea>
        {showBetslip && <BetSlip />}
      </MainAreaWrapper>
    </>
  );
}

function LeagueSeparator(props) {
  const { game, i, scheduleForDate } = props;

  let league = null;
  if (i === 0) {
    league = game.league;
  }
  if (i > 0 && scheduleForDate.games[i - 1].league !== game.league) {
    league = game.league;
  }

  if (!league) return null;

  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: i === 0 ? 'var(--space-xxs)' : 'var(--space-md)',
        marginBottom: 'var(--space-xs)',
      }}
    >
      <div
        style={{
          flex: 1,
          height: '2px',
          backgroundColor: 'var(--color-text-light)',
          marginRight: 'var(--space-md)',
        }}
      />
      <TeamLeagueLogo
        iconSize={28}
        displayTeam={false}
        league={game.league}
        style={{ margin: '0 4px' }}
      />
      <h6 style={{ fontWeight: 'bold', margin: 0 }}>{game.league}</h6>
      <div
        style={{
          flex: 1,
          height: '2px',
          backgroundColor: 'var(--color-text-light)',
          marginLeft: 'var(--space-md)',
        }}
      />
    </Row>
  );
}

function _hasOdds(game) {
  const ba = game.best_available || {};
  return (
    ba.home_ml !== null ||
    ba.away_ml !== null ||
    ba.home_spread !== null ||
    ba.away_spread !== null ||
    ba.over_total !== null ||
    ba.under_total !== null
  );
}