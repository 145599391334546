import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Analytics from 'amplitude/Analytics';

// utils
import { BETA_AUTOSYNC_BOOKS, BACKEND_API_URL } from 'utils';

// components
import { AuthButton, IconButton } from 'components/AuthButton';
import { Row, Col } from 'components/generic/Layout';
import { BetlinkStamp } from 'components/generic/Logos';
import AutoSyncController from 'components/AutoSyncController';
import { useCopyToClipboard, useUpdateEffect } from 'react-use';
import { IoCopyOutline, IoShare } from 'react-icons/io5';

const BASE_REVIEW_URL = 'sportsbooks/reviews/';

export default function AffiliateLink(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    selectedState: state.affiliatesReducer.selectedState,
    referralCode: state.affiliatesReducer.referralCode,
    affiliates: state.affiliatesReducer.affiliates,
    sportsbookInfoMap: state.affiliatesReducer.sportsbookInfoMap,
  }));
  const { user, selectedState, referralCode, affiliates, sportsbookInfoMap } =
    reduxProps;

  const { book, text, fromPage, shape, fallbackToGeneric, src } = props;

  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [copySuccess, setCopySuccess] = useState(false);

  useUpdateEffect(() => {
    if (copyState.value) {
      setCopySuccess(true);
    }
    const to = setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
    return () => clearTimeout(to);
  }, [copyState]);

  const state = selectedState
    ? selectedState
    : user?.state
    ? user?.state
    : 'Ontario';
  let links = null;
  let bonuses = null;
  let aff = null;
  if (affiliates && book) {
    aff = affiliates.find(b => b.id === book.id);
    if (aff) {
      links = aff.affiliate_links;
      bonuses = aff.signup_bonuses;
    }
  }
  let bonus = null;
  if (bonuses) bonus = bonuses[0]?.bonus;

  // refCode = referralCode, then user referralCode, then link referral_code,
  let refCode = null;
  if (referralCode) refCode = referralCode;
  if (!refCode && user) refCode = user.referral_code;
  if (!refCode && links) refCode = links[0]?.referral_code;

  if ((!links || links?.length < 1) && !fallbackToGeneric) {
    return null;
  }

  if ((!links || links?.length < 1) && fallbackToGeneric) {
    const imgsrc = props.src
      ? props.src
      : book?.generic_banner || aff?.generic_banner;
    if (!imgsrc) {
      return (
        <>
          <Col
            style={{
              borderRadius: '4px',
              border: '2px solid var(--color-text-light)',
              padding: 'var(--space-xxs)',
              ...props.fallbackStyle,
            }}
          >
            <b>{book?.name}</b>
          </Col>
        </>
      );
    }
    return (
      <>
        {/* hidden text for ctrl f */}
        <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
          <small style={{ position: 'absolute', color: 'transparent' }}>
            {book?.name}
          </small>
        </div>
        <img
          style={{
            height: 'auto',
            width: '100%',
            resizeMode: 'cover',
            borderRadius: '4px',
            ...props.imgStyle,
          }}
          src={imgsrc}
          alt={book.name}
        />
      </>
    );
  }

  bonus = bonuses && bonuses.length > 0 ? bonuses[0].bonus : null;
  let user_books = [];
  if (user) {
    user_books = user.books.map(book => book.name);
  }

  if (shape === 'sharableLink') {
    if (links?.length < 1) return null;
    const affID = links[0]?.id;
    var link = `${BACKEND_API_URL}api/redirect/${affID}/?user=${user?.id}&platform=web`;
    if (!!props.referralCode) link = link + `&r_id=${props.referralCode}`;
    if (fromPage) link = link + `&from_page=${fromPage}`;
    return (
      <>
        {!!copySuccess ? (
          <small>Link Copied!</small>
        ) : (
          <IconButton
            colorTheme="onbbluetext"
            iconName={IoShare}
            title={`Share ${book.name}`}
            onPress={() => copyToClipboard(encodeURI(link))}
          />
        )}
      </>
    );
  }

  if (shape === 'box') {
    return (
      <>
        <Outlink
          overrideChildren
          btnTheme="borderless"
          containerStyle={{ borderRadius: '8px', margin: 0 }}
          btnStyle={{
            borderRadius: '4px',
            padding: 0,
            flexDirection: 'column',
          }}
          state={state}
          {...props}
        >
          <img
            style={{
              height: 'auto',
              width: '100%',
              resizeMode: 'cover',
              borderRadius: '4px',
              ...props.imgStyle,
            }}
            src={
              src
                ? src
                : book.preferred_open_banner ||
                  book.generic_banner ||
                  aff.preferred_open_banner ||
                  aff.open_banner
            }
            alt={book.name}
            title={book.name}
          />
          {/*
          {props.hideText || state === 'Ontario' ? (
            <></>
          ) : text ? (
            text
          ) : bonus ? (
            `Get $${bonus} bonus at ${book.name}`
          ) : (
            `Open account at ${book.name}`
          )}
      */}

          {!props.hideText &&
            user?.state !== 'Ontario' &&
            (text || (sportsbookInfoMap && sportsbookInfoMap[book.id])) && (
              <div style={{ textAlign: 'center' }}>
                {text || sportsbookInfoMap[book.id]?.bonus}
              </div>
            )}
        </Outlink>
      </>
    );
  }

  if (shape === 'bar') {
    return (
      <Row style={{ alignItems: 'center', ...props.containerStyle }}>
        {/* hidden text for ctrl f */}
        <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
          <small style={{ position: 'absolute', color: 'transparent' }}>
            {book?.name}
          </small>
        </div>
        <div style={{ flex: 2 }}>
          <Outlink
            overrideChildren
            btnTheme="borderless"
            containerStyle={{ borderRadius: '8px', margin: 0 }}
            btnStyle={{
              borderRadius: '4px',
              padding: 0,
              flexDirection: 'column',
            }}
            state={state}
            {...props}
          >
            <img
              style={{
                height: 'auto',
                width: '100%',
                resizeMode: 'cover',
                borderRadius: '4px',
                ...props.imgStyle,
              }}
              src={
                sportsbookInfoMap[book.id]?.betlink_banner ||
                book.open_banner ||
                book.generic_banner
              }
              alt={book.name}
              title={book.name}
            />
          </Outlink>
        </div>

        {props.showMinDeposit &&
          sportsbookInfoMap &&
          sportsbookInfoMap[aff?.id] && (
            <Col style={{ flex: 0.5 }}>
              {!!sportsbookInfoMap[aff.id].affiliate_min_deposit_text &&
              sportsbookInfoMap[aff.id].affiliate_min_deposit_text !== '' &&
              sportsbookInfoMap[aff.id].affiliate_min_deposit_text !== '0' ? (
                <h2 style={{ margin: 0, textAlign: 'center' }}>
                  {sportsbookInfoMap[aff.id].affiliate_min_deposit_text}
                </h2>
              ) : (
                <>
                  <h2 style={{ margin: 0 }}>
                    $
                    {props.useGroupsMinDeposit &&
                    !!sportsbookInfoMap[aff.id].group_affiliate_min_deposit &&
                    sportsbookInfoMap[aff.id].group_affiliate_min_deposit !==
                      '0'
                      ? sportsbookInfoMap[aff.id].group_affiliate_min_deposit
                      : sportsbookInfoMap[aff.id].affiliate_min_deposit}
                  </h2>
                  <span>Min Deposit</span>
                </>
              )}
            </Col>
          )}
      </Row>
    );
  }

  if (shape === 'finder') {
    return (
      <>
        <Row
          style={{
            alignItems: 'stretch',
            margin: 'var(--space-sm)',
          }}
        >
          <div
            style={{
              flex: 2,
              alignSelf: 'center',
              margin: '0 var(--space-xxs)',
            }}
          >
            <Outlink
              overrideChildren
              btnTheme="borderless"
              containerStyle={{ borderRadius: '8px', margin: 0 }}
              btnStyle={{ borderRadius: '8px', padding: 0 }}
              state={state}
              {...props}
            >
              <img
                style={{
                  height: 'auto',
                  width: '100%',
                  resizeMode: 'cover',
                  borderRadius: 8,
                  marginTop: 'auto',
                }}
                src={
                  props.usePreferred
                    ? book.preferred_banner
                    : book.preferred_open_banner || book.generic_banner
                }
                alt={book.name}
                title={book.name}
              />
            </Outlink>
          </div>
          {state !== 'Ontario' && (
            <Outlink
              colorTheme="inverted"
              linkStyle={{ flex: 1, margin: '0 var(--space-xxs)' }}
              containerStyle={{
                height: '100%',
                borderRadius: 'var(--std-border-radius)',
                margin: 0,
              }}
              btnStyle={{
                height: '100%',
                borderRadius: 'var(--std-border-radius)',
                backgroundColor: book.color || 'var(--color-primary)',
                borderColor: book.color || 'var(--color-primary)',
                color: 'white',
              }}
              state={state}
              {...props}
            ></Outlink>
          )}
          <Col style={{ flex: 0.66 }}>
            {book.review_slug ? (
              <Link to={BASE_REVIEW_URL + book.review_slug}>
                Read the Full Review
              </Link>
            ) : (
              <span>Review coming soon</span>
            )}
          </Col>
          {!book.autosync_exclude_states.includes(state) &&
            (book.is_autosyncable ||
              BETA_AUTOSYNC_BOOKS.includes(book.name)) && (
              <AutoSyncController book={book} />
            )}
          {(book.autosync_exclude_states.includes(state) ||
            (!book.is_autosyncable &&
              !BETA_AUTOSYNC_BOOKS.includes(book.name))) && (
            <AuthButton
              overrideChildren
              containerStyle={{
                borderRadius: '8px',
                margin: '0 var(--space-xxs)',
              }}
              btnStyle={{
                height: '100%',
                flexDirection: 'column',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                padding: 0,
              }}
              onPress={
                (book.is_autosyncable ||
                  BETA_AUTOSYNC_BOOKS.includes(book.name)) &&
                !book?.autosync_exclude_states?.includes(state)
                  ? props.onPressLink
                    ? props.onPressLink
                    : () => (window.location.href = '/betlink')
                  : () => {}
              }
            >
              <BetlinkStamp
                blue={
                  (book.is_autosyncable ||
                    BETA_AUTOSYNC_BOOKS.includes(book.name)) &&
                  !book?.autosync_exclude_states?.includes(state)
                }
                style={{
                  width: 54,
                  height: 54,
                  opacity: book.is_autosyncable ? 1 : 0.66,
                }}
              />
              {(book.is_autosyncable ||
                BETA_AUTOSYNC_BOOKS.includes(book.name)) &&
              !book?.autosync_exclude_states?.includes(state) ? (
                <span style={{ color: 'var(--color-primary)' }}>Link</span>
              ) : (
                <span
                  style={{
                    color: 'var(--color-text-light)',
                    textAlign: 'center',
                  }}
                >
                  Coming Soon
                </span>
              )}
            </AuthButton>
          )}
        </Row>
      </>
    );
  }

  if (!links) return <></>;
  if (state === 'Ontario') {
    return (
      <Outlink {...props} state={state}>
        {!!props.children ? props.children : text || `Sign up at ${book.name}`}
      </Outlink>
    );
  }
  //if (!bonus) return <></>;

  return (
    <Outlink {...props} state={state}>
      <>
        {!!props.children
          ? props.children
          : text
          ? text
          : `Sign up at ${book.name}`}
      </>
    </Outlink>
  );
}

export function Outlink(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    referralCode: state.affiliatesReducer.referralCode,
    affiliates: state.affiliatesReducer.affiliates,
  }));

  const { user, referralCode, affiliates } = reduxProps;

  const { book, fromPage, state } = props;

  if (props.noLink) {
    return props.children;
  }

  let links = null;
  if (affiliates) {
    let aff = affiliates.find(b => b.id === book.id);
    if (aff) {
      links = aff.affiliate_links;
    }
  }

  if (!links) {
    return null;
  }

  if (links.length < 1) {
    return null;
  }
  let refCode = null;
  if (referralCode) refCode = referralCode;
  if (!refCode && user) refCode = user.referral_code;
  if (!refCode && links) refCode = links[0]?.referral_code;
  //const link = links[0].link;
  const affID = links[0].id;
  var link = `${BACKEND_API_URL}api/redirect/${affID}/?user=${user?.id}&platform=web`;
  if (fromPage) link = link + `&from_page=${fromPage}`;
  if (!!props.referralCode) link = link + `&r_id=${props.referralCode}`;

  let user_books = [];
  if (user) {
    user_books = user.books.map(book => book.name);
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      style={props.linkStyle}
      href={link}
      // prevent right click
      onContextMenu={ev => {
        ev.preventDefault();
        return false;
      }}
      // prevent middle click
      onAuxClick={ev => {
        ev.preventDefault();
        return false;
      }}
      onClick={() => {
        // this doesn't seem to run if user middle clicks/right click > open new tab
        // therefore, the two prevatative measure above
        Analytics.track(Analytics.events.CLICK_AFFILIATE, user?.id, {
          affiliate_book: book.name,
          already_had_book: user_books?.includes(book.name),
          from_page: fromPage,
          referral_code: refCode || 'none',
          state: state,
        });
        if (props.onPress) {
          props.onPress();
        }
      }}
    >
      {props.children}
    </a>
  );
  //return (
  //  <LinkButton
  //    to={{
  //      pathname: '/outlink',
  //      state: {
  //        link: link,
  //        bookname: book.name,
  //      },
  //    }}
  //    ampData={{
  //      event: Analytics.events.CLICK_AFFILIATE,
  //      data: {
  //        affiliate_book: book.name,
  //        already_had_book: user_books.includes(book.name),
  //        from_page: fromPage,
  //        referral_code: refCode || 'none',
  //        state: state,
  //      },
  //    }}
  //    linkStyle={{ whiteSpace: 'wrap' }}
  //    {...props}
  //  />
  //);
}
