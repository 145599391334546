import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IoIosMenu, IoIosClose } from 'react-icons/io';
import { NavLink } from 'react-router-dom';

// components
import { Row } from 'components/generic/Layout';
import { BetstampWordBlueText } from 'components/generic/Logos';
import ProfilePic from 'components/generic/ProfilePic';
import { AuthButton, IconButton } from 'components/AuthButton';

const Wrapper = styled.div`
  background: ${props => {
    if (props.open) {
      return 'var(--color-fg)';
    } else {
      return 'transparent';
    }
  }};
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  position: relative;
  transition: all var(--std-transition);
  min-height: 6vh;
`;

const DesktopLayout = styled.div`
  display: block;
  @media only screen and (max-width: 625px) {
    display: none;
  }
`;

const PhoneLayout = styled.div`
  display: none;
  @media only screen and (max-width: 625px) {
    display: block;
  }
`;

const NavLinks = styled.nav`
  & > a {
    display: inline-block;
    text-decoration: none;
    font-size: var(--text-base-size);
    color: var(--color-text);
    margin: 0 var(--space-md);
    opacity: 0.8;
    transition: all var(--std-transition);
    border-bottom: 3px solid transparent;
    cursor: pointer;
    padding: ${props => {
      if (props.mobile) {
        return 'var(--space-sm) 0';
      } else {
        return '';
      }
    }};
  }
  & > a:hover {
    opacity: 1;
    transform: translate3d(0, -2px, 0);
    border-bottom: 3px solid var(--color-primary);
  }
`;

const AnimateOpen = styled.div`
  max-height: 500px;
  opacity: 1;
  @keyframes AnimateOpen {
    0% {
      max-height: 0;
      opacity: 0;
    }
    100% {
      max-height: 500px;
      opacity: 1;
    }
  }
  animation-name: AnimateOpen;
  animation-duration: 333ms;
  animation-timing-function: ease-in-out;
`;

const AnimateClose = styled.div`
  max-height: 0;
  pointer-events: none;
  opacity: 0;
  @keyframes AnimateClose {
    0% {
      max-height: 500px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      opacity: 0;
    }
  }
  animation-name: AnimateClose;
  animation-duration: 333ms;
  animation-timing-function: ease-in-out;
`;

const MenuOptions = ({ userToken, user, mobile, closeMenu }) => (
  <NavLinks
    style={{
      display: 'flex',
      flexDirection: mobile ? 'column' : 'row',
      alignItems: mobile ? 'center' : 'baseline',
      whiteSpace: 'nowrap',
    }}
    mobile={mobile}
  >
    {userToken ? (
      <>
        <NavLink
          exact
          to="/games"
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          Games
        </NavLink>
        <NavLink
          exact
          to="/my-picks"
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          My Picks
        </NavLink>
        <NavLink
          exact
          to="/analysis"
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          Analysis
        </NavLink>
        <NavLink
          exact
          to="/find-bettors"
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          Find Bettors
        </NavLink>
        <NavLink
          exact
          to="/best-sportsbooks"
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          Sportsbooks
        </NavLink>
        <NavLink
          exact
          to="/sell-picks"
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          Sell My Picks
        </NavLink>
        <NavLink
          exact
          to="/settings"
          style={{ opacity: 0.75, position: 'relative' }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          <Row style={{ flexFlow: 'row nowrap' }}>
            {user && user.username ? user.username : 'My Profile'}
            <ProfilePic
              user={user}
              style={{
                width: '1.5rem',
                height: '1.5rem',
                marginLeft: 'var(--space-xs)',
              }}
            />
          </Row>
        </NavLink>
      </>
    ) : (
      <>
        <NavLink
          exact
          to="/best-sportsbooks"
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
          onClick={mobile ? () => closeMenu() : null}
        >
          Sportsbooks
        </NavLink>
        <a
          href="https://betstamp.com/tutorials"
          target='_blank'
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
        >
          Tutorials
        </a>
        <a
          href="https://betstamp.com/faqs"
          target='_blank'
          style={{ opacity: 0.75 }}
          activeStyle={{ opacity: 1 }}
        >
          FAQ's
        </a>
        <span>
          <NavLink
            exact
            to="/login"
            style={{
              opacity: 0.75,
              margin: 'var(--space-sm)',
              textDecoration: 'none',
            }}
            activeStyle={{ opacity: 1 }}
            onClick={mobile ? () => closeMenu() : null}
          >
            <AuthButton
              containerStyle={{
                display: 'inline-flex',
                width: 'auto',
                minWidth: '7rem',
              }}
              onPress={() => {}}
            >
              Login
            </AuthButton>
          </NavLink>
        </span>
        <span>
          <NavLink
            exact
            to="/signup"
            style={{
              opacity: 0.75,
              margin: 'var(--space-sm)',
              textDecoration: 'none',
            }}
            activeStyle={{ opacity: 1 }}
            onClick={mobile ? () => closeMenu() : null}
          >
            <AuthButton
              colorTheme="inverted"
              containerStyle={{
                display: 'inline-flex',
                width: 'auto',
                minWidth: '7rem',
              }}
              onPress={() => {}}
            >
              Sign Up
            </AuthButton>
          </NavLink>
        </span>
      </>
    )}
  </NavLinks>
);

export default function Header() {
  // redux props
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    user: state.authReducer.user,
  }));

  const { userToken, user } = reduxProps;

  const [open, setOpen] = useState(false);
  const [wasItOpen, setWasItOpen] = useState(false); // prevents it starting open on mobile and flashing closed

  return (
    <>
      <Wrapper open={open}>
        <Row
          desktop
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            lineHeight: 0,
          }}
        >
          <NavLink exact to="/" style={{ margin: 0 }}>
            <BetstampWordBlueText height={64} />
          </NavLink>
        </Row>
        <Row
          mobile
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            lineHeight: 0,
          }}
        >
          <NavLink exact to="/" style={{ margin: 0 }}>
            <BetstampWordBlueText height={48} />
          </NavLink>
        </Row>

        <Row
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
          reverse
        >
          <DesktopLayout>
            <MenuOptions user={user} userToken={userToken} />
          </DesktopLayout>

          <PhoneLayout>
            <IconButton
              btnTheme="borderless"
              containerStyle={{
                padding: '0 var(--space-sm)',
              }}
              onPress={() => {
                setOpen(!open);
                setWasItOpen(true);
              }}
              iconName={open ? IoIosClose : IoIosMenu}
              iconSize="30"
              iconTitle={open ? 'Close Menu' : 'Menu'}
              iconColor="var(--color-text)"
            />
          </PhoneLayout>
        </Row>
      </Wrapper>
      <PhoneLayout style={{ backgroundColor: 'var(--color-fg)' }}>
        <Row>
          {wasItOpen ? (
            <>
              {open ? (
                <AnimateOpen>
                  <MenuOptions
                    user={user}
                    userToken={userToken}
                    mobile
                    closeMenu={() => setOpen(!open)}
                  />
                </AnimateOpen>
              ) : (
                <AnimateClose>
                  <MenuOptions
                    user={user}
                    userToken={userToken}
                    mobile
                    closeMenu={() => setOpen(!open)}
                  />
                </AnimateClose>
              )}
            </>
          ) : null}
        </Row>
      </PhoneLayout>
    </>
  );
}
