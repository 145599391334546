import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { LinkButton } from 'components/AuthButton';

// constants
import {
  AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
  AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
} from 'utils';

import {
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoMailOutline,
  IoLogoTiktok,
} from 'react-icons/io5';

// actions
import { resetErrorsUser } from 'actions';
import { Link } from 'react-router-dom';
import { Row } from 'components/generic/Layout';

export default function FourOhFour(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
  }));

  const { userToken } = reduxProps;

  useEffect(() => {
    dispatch(resetErrorsUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>
      <div
        className="container-full"
        style={{ maxWidth: '650px', textAlign: 'center' }}
      >
        <h1>This page doesn't exist!</h1>
        <br />
        <LinkButton
          to="/"
          containerStyle={{
            ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
            ...AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        >
          Take Me Home
        </LinkButton>
        <br />
        <br />
        <br />
        <Row>
          {userToken ? (
            <>
              <Link to="/games">Games</Link>
              <Link to="/my-picks">My Picks</Link>
              <Link to="/find-bettors">Find Bettors</Link>
              <Link to="/analysis">Analysis</Link>
              <Link to="/myprofile">My Profile</Link>
            </>
          ) : (
            <>
              <Link to="/login">Login</Link>
              <Link to="/signup">Signup</Link>
              <a href="https://betstamp.com/tutorials" target="_blank">Tutorial</a>
              <a href="https://betstamp.com/faqs" target="_blank">FAQ&apos;s</a>
            </>
          )}
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <a
            href="https://www.instagram.com/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram
              title="betstamp Instagram"
              style={{
                fontSize: 'var(--text-xl)',
                padding: '0 var(--space-sm)',
              }}
            />
          </a>
          <a
            href="https://twitter.com/Betstamp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoTwitter
              title="betstamp Twitter"
              style={{
                fontSize: 'var(--text-xl)',
                padding: '0 var(--space-sm)',
              }}
            />
          </a>
          <a
            href="https://vm.tiktok.com/ZMe9VSXm1/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoTiktok
              title="betstamp Tik Tok"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoLinkedin
              title="betstamp LinkedIn"
              style={{
                fontSize: 'var(--text-xl)',
                padding: '0 var(--space-sm)',
              }}
            />
          </a>
          <a
            href="mailto:contact@betstamp.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoMailOutline
              title="betstamp Email"
              style={{
                fontSize: 'var(--text-xl)',
                padding: '0 var(--space-sm)',
              }}
            />
          </a>
        </Row>
      </div>
    </>
  );
}
