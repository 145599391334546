import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useMedia } from 'react-use';
import { useLocation } from 'react-router-dom';

// components
import { AuthButton, IconButton } from 'components/AuthButton';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Row, Col } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import SelfUserProfileHeader from 'components/user_profile/SelfUserProfileHeader';
import EditProfile from 'pages/Settings/Tabs/EditProfile';
import OddsSettings from 'pages/Settings/Tabs/OddsSettings';
import DisplaySettings from 'pages/Settings/Tabs/DisplaySettings';
import ConnectedAccounts from 'pages/Settings/Tabs/ConnectedAccounts';
import ReferralSupport from 'pages/Settings/Tabs/ReferralSupport';
import SportsBooks from 'pages/Settings/Tabs/SportsBooks';
import AutoSyncSettings from 'pages/Settings/Tabs/AutoSyncSettings';
import { IoClose } from 'react-icons/io5';
import Snackbar from '@material-ui/core/Snackbar';

// actions
import {
  logoutUser,
  getUser,
  cleanAutoSyncStore,
  sendEmailVerification,
} from 'actions';

export default function Settings(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    isLoadingLogout: state.authReducer.isLoadingLogout,
    isLoading: state.authReducer.isLoading,
    apiError: state.authReducer.apiError,
  }));
  const { user, isLoading, isLoadingLogout, apiError } = reduxProps;
  const location = useLocation();
  const startState = location?.startState
    ? location.startState
    : 'EDIT PROFILE';
  const [currView, setCurrView] = useState(startState);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [closedEmailUnverified, setClosedEmailUnverified] = useState(false);

  const mobile = useMedia('(max-width: 768px)');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  if (isLoading) {
    return (
      <Col style={{ height: '100%' }}>
        <ActivityIndicator size={3} />
      </Col>
    );
  }

  if (apiError) {
    return (
      <ErrorDisplay
        error={apiError}
        message={'getting your profile!'}
        retry={() => dispatch(getUser())}
      />
    );
  }

  return (
    <Col
      className="container"
      style={{
        width: mobile ? '100vw' : '92vw',
        padding: mobile ? 0 : '0 var(--space-md)',
        maxWidth: '1600px',
      }}
    >
      {!user.email_verified && !closedEmailUnverified && (
        <Row
          style={{
            flex: 1,
            backgroundColor: 'var(--color-fg)',
            width: '100%',
            zIndex: 99999,
          }}
          className={'flash'}
        >
          <Row
            style={{
              flex: 1,
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0 var(--space-sm)',
            }}
          >
            {mobile ? (
              <p style={{ margin: 0 }}>Your email is unverified! &nbsp;</p>
            ) : (
              <h6 style={{ margin: 0 }}>Your email is unverified! &nbsp;</h6>
            )}
            <AuthButton
              btnTheme={mobile && 'borderless'}
              containerStyle={{
                maxWidth: '14rem',
              }}
              onPress={() => {
                dispatch(sendEmailVerification());
                setOpenSnackbar(true);
              }}
            >
              {mobile ? 'Verify' : 'Send Verification Email'}
            </AuthButton>
          </Row>
          <Col
            style={{
              flex: 0,
              justifyContent: 'center',
              padding: '0 var(--space-sm)',
            }}
          >
            <IconButton
              iconName={IoClose}
              iconColor="var(--color-text)"
              size={28}
              onPress={() => {
                setClosedEmailUnverified(true);
              }}
            />
          </Col>
        </Row>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email Verification Sent. Please check your inbox to complete the verification process. If you did not receive an email, please check your junk folder."
        action={
          <>
            <IconButton onPress={handleClose} iconName={IoClose} />
          </>
        }
      />
      <Col
        style={{
          flex: 9,
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: mobile ? 'var(--space-xxl)' : 0,
        }}
      >
        <SelfUserProfileHeader user={user} onMobile={mobile} />
        {mobile ? (
          <Col
            style={{
              width: '100%',
              alignItems: 'stretch',
              justifyContent: 'flex-start',
            }}
          >
            <SettingsBar
              handleTabChange={item => setCurrView(item)}
              selected={currView}
              isLoadingLogout={isLoadingLogout}
              mobile={mobile}
            />
            <SettingsSubPage pageName={currView} mobile={true} />
          </Col>
        ) : (
          <Row
            style={{
              width: '100%',
              alignItems: 'flex-start',
              minHeight: '101vh',
            }}
          >
            <SettingsBar
              handleTabChange={item => setCurrView(item)}
              selected={currView}
              isLoadingLogout={isLoadingLogout}
              mobile={mobile}
            />

            <SettingsSubPage pageName={currView} mobile={false} />
          </Row>
        )}
      </Col>
    </Col>
  );
}

const StyledTabs = styled.div`
  flex: 0;
  cursor: pointer;
  background-color: ${props =>
    props.highlight ? 'var(--color-active)' : 'null'};
  border-right: ${props =>
    props.highlight && !props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  border-bottom: ${props =>
    props.highlight && props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  font-weight: ${props => (props.highlight ? 700 : 200)};
  padding: var(--space-xs) var(--space-md);
  transition: all var(--std-transition);
  &:hover {
    background-color: var(--color-active);
  }
`;

//Settings tabs
const TABS = [
  'EDIT PROFILE',
  'ODDS SETTINGS',
  'DISPLAY SETTINGS',
  //'BETLINK SETTINGS',
  'MY SPORTSBOOKS',
  'CONNECTED ACCOUNTS',
  'REFERRALS & SUPPORT',
];

function SettingsBar(props) {
  const dispatch = useDispatch();

  const { handleTabChange, isLoadingLogout, mobile } = props;

  if (mobile) {
    return (
      <Row
        style={{
          margin: 'var(--space-sm) 0',
          overflowX: 'scroll',
          flexFlow: 'row nowrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {TABS.map((item, key) => {
          return (
            <StyledTabs
              key={key}
              onClick={() => handleTabChange(item)}
              highlight={props.selected === item}
              mobile
            >
              <div style={{ whiteSpace: 'nowrap' }}>{item}</div>
            </StyledTabs>
          );
        })}
      </Row>
    );
  }

  return (
    <Col
      style={{
        display: 'initial',
        position: 'sticky',
        marginTop: 'var(--space-lg)',
        top: 20,
        flex: 0.3,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      }}
    >
      {TABS.map((item, key) => {
        return (
          <StyledTabs
            key={key}
            onClick={() => handleTabChange(item)}
            highlight={props.selected === item}
          >
            <div>{item}</div>
          </StyledTabs>
        );
      })}
      <hr style={{ borderColor: '#9b9b9b' }} />
      <Row
        style={{
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'stretch',
        }}
      >
        <AuthButton
          btnTheme="borderless"
          btnStyle={{ paddingLeft: 'var(--space-md)' }}
          textStyle={{ textAlign: 'left' }}
          colorTheme="danger"
          isLoading={isLoadingLogout}
          onPress={() => {
            dispatch(logoutUser());
            dispatch(cleanAutoSyncStore());
          }}
        >
          Log out
        </AuthButton>
      </Row>
    </Col>
  );
}

function SettingsSubPage(props) {
  const { pageName, mobile } = props;
  const pageStyle = mobile
    ? { width: '90%', marginBottom: 'var(--space-xxl)' }
    : {
        marginBottom: 'var(--space-xxl)',
        paddingLeft: 'var(--space-xl)',
      };

  return (
    <Col
      style={{
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      }}
    >
      {pageName === 'EDIT PROFILE' && <EditProfile style={pageStyle} mobile />}
      {pageName === 'ODDS SETTINGS' && (
        <OddsSettings style={pageStyle} mobile />
      )}
      {pageName === 'DISPLAY SETTINGS' && (
        <DisplaySettings style={pageStyle} mobile />
      )}
      {pageName === 'MY SPORTSBOOKS' && <SportsBooks style={pageStyle} mobile />}

      {pageName === 'CONNECTED ACCOUNTS' && (
        <ConnectedAccounts style={pageStyle} mobile />
      )}
      {pageName === 'REFERRALS & SUPPORT' && (
        <ReferralSupport style={pageStyle} mobile />
      )}
    </Col>
  );
}
