import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
  IoMailOutline,
  IoCheckmarkCircleOutline,
  IoPersonCircleOutline,
} from 'react-icons/io5';

// utils
import { isEmailValid } from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import { TOS } from 'components/auth/signup';
import GoogleAuthButton from 'components/GoogleAuthButton';
import AppleAuthButton from 'components/AppleAuthButton';
import TwitterAuthButton from 'components/TwitterAuthButton';
import ActivityIndicator from 'components/generic/ActivityIndicator';

// actions
import {
  loginUser,
  checkEmailAvailable,
  checkUsernameAvailable,
  getUser,
} from 'actions';

export default function IntakeCreateLogin(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    userToken: state.authReducer.userToken,
    theme: state.settingsReducer.theme,
    isLoading: state.authReducer.isLoading,
    userLoginSuccess: state.authReducer.userLoginSuccess,
    apiError: state.authReducer.apiError,
    emailIsAvailable: state.authReducer.emailIsAvailable,
    usernameIsAvailable: state.authReducer.usernameIsAvailable,
    socialLoginSuccess: state.authReducer.socialLoginSuccess,
    isSocialAuthingUser: state.authReducer.isSocialAuthingUser,
  }));
  const {
    user,
    userToken,
    isLoading,
    userLoginSuccess,
    apiError,
    emailIsAvailable,
    usernameIsAvailable,
    socialLoginSuccess,
    isSocialAuthingUser,
  } = reduxProps;

  const { givenEmail } = props;

  const [alreadyHasAccount, setAlreadyHasAccount] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [valids, setValids] = useState({});
  const [login, setLogin] = useState({
    accepted_TC: false,
    betstamp_email: givenEmail || '',
    password: '',
  });
  const [forceEmailInvalid, setForceEmailInvalid] = useState(null);
  const [forcePasswordInvalid, setForcePasswordInvalid] = useState(null);

  useUpdateEffect(() => {
    if (userToken && !user) {
      dispatch(getUser());
    }
  }, [userToken]);

  useEffect(() => {
    const emailValid = isEmailValid(login.betstamp_email);
    if (emailValid) {
      setForceEmailInvalid(null);
      dispatch(checkEmailAvailable(login.betstamp_email));
    } else {
      setForceEmailInvalid('Invalid email');
    }

    let usernameValid = Boolean(login.username);
    if (login.username) {
      if (
        !login.username ||
        login.username.trim() === '' ||
        login.username.length > 16
      ) {
        usernameValid = false;
      }
      if (usernameValid) {
        dispatch(checkUsernameAvailable(login.username));
      }
    }

    const passValid = login.password.length > 0;
    if (!passValid) {
      setForcePasswordInvalid('Invalid password');
    } else {
      setForcePasswordInvalid(null);
    }

    setValids({ ...valids, password: passValid, email: emailValid });

    if (createAccount)
      props.setNewAccount({
        ...login,
        newAccountValid:
          usernameValid && passValid && emailValid && login.accepted_TC,
      });
  }, [login]);

  useEffect(() => {
    if (createAccount)
      props.setNewAccount({
        ...login,
        emailIsAvailable: emailIsAvailable,
        usernameIsAvailable: usernameIsAvailable,
      });
  }, [emailIsAvailable, usernameIsAvailable]);

  const _login = ev => {
    if (ev) ev.preventDefault();
    if (valids.email && valids.password) {
      dispatch(
        loginUser({ email: login.betstamp_email, password: login.password })
      );
    }
  };

  const loginDisabled = !valids.email || !valids.password;

  if (userToken && !alreadyHasAccount) {
    return (
      <>
        <h5>betstamp Account</h5>
        <p>
          Account with username <b>{user.username || user.email}</b> will be
          connected
        </p>
      </>
    );
  }

  return (
    <>
      <h5>betstamp Account</h5>
      {!alreadyHasAccount && !createAccount && (
        <>
          <p>Create or connect a betstamp account to track all your bets.</p>
          <Row
            style={{ gap: 'var(--space-md)', marginBottom: 'var(--space-md)' }}
          >
            <AuthButton
              type="Button"
              onPress={() => {
                setAlreadyHasAccount(true);
                setLogin({ ...login, betstamp_email: givenEmail || '' });
              }}
            >
              I already have an account
            </AuthButton>
            <AuthButton
              type="Button"
              onPress={() => {
                setCreateAccount(true);
                setLogin({ ...login, betstamp_email: givenEmail || '' });
              }}
            >
              Create an account
            </AuthButton>
          </Row>
        </>
      )}
      {alreadyHasAccount && (
        <>
          {!userLoginSuccess && !socialLoginSuccess && (
            <>
              <p>
                Connect an existing betstamp account by logging in below. Don't
                have an account?{' '}
                <span
                  style={{
                    color: 'var(--color-primary)',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setCreateAccount(true);
                    setAlreadyHasAccount(false);
                  }}
                >
                  Click here to create a betstamp account
                </span>
              </p>
              <Row style={{ gap: 'var(--space-xxxl)', alignItems: 'center' }}>
                <Col>
                  <AuthTextInput
                    label="Email"
                    name="betstamp_email"
                    type="email"
                    textContentType={'emailAddress'}
                    leftIcon={IoMailOutline}
                    rightIcon={valids.email ? IoCheckmarkCircleOutline : null}
                    rightIconColor={'var(--color-success)'}
                    errorText={forceEmailInvalid}
                    placeholder={'yourname@gmail.com'}
                    //containerStyle={{ maxWidth: '400px' }}
                    onEndEditing={() => {
                      if (!valids.email) setForceEmailInvalid('Invalid email');
                    }}
                    onChangeText={text =>
                      setLogin({ ...login, betstamp_email: text })
                    }
                    value={login.betstamp_email}
                    autoFocus={!login.betstamp_email}
                  />
                  <AuthTextInput
                    label="Password"
                    name="password"
                    placeholder={'Password'}
                    //containerStyle={{ maxWidth: '400px' }}
                    leftIcon={IoLockClosedOutline}
                    rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
                    rightIconOnPress={() => setShowPassword(!showPassword)}
                    type="password"
                    onChangeText={text =>
                      setLogin({ ...login, password: text })
                    }
                    autoFocus={!!login.betstamp_email}
                  />
                  {apiError ? (
                    <ErrorDisplay
                      error={apiError}
                      basic={true}
                      containerStyle={{
                        textAlign: 'center',
                        padding: 'var(--space-xs)',
                        backgroundColor: 'var(--color-fg)',
                        borderRadius: 'var(--std-border-radius)',
                        //maxWidth: '400px',
                      }}
                    />
                  ) : (
                    <div style={{ padding: 'var(--space-md)' }} />
                  )}
                  <AuthButton
                    type="button"
                    //containerStyle={{ maxWidth: '400px' }}
                    isLoading={isLoading}
                    disabled={loginDisabled}
                    onPress={() => _login()}
                  >
                    Connect
                  </AuthButton>
                </Col>
                <Col
                  desktop
                  style={{
                    flex: 0.15,
                  }}
                >
                  OR
                </Col>
                <Col>
                  {isSocialAuthingUser ? (
                    <ActivityIndicator size={2} />
                  ) : (
                    <>
                      <AppleAuthButton login />
                      <GoogleAuthButton login />
                      <TwitterAuthButton login />
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          {(!!userLoginSuccess || socialLoginSuccess) && (
            <Row
              style={{
                gap: 'var(--space-xs)',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <IoCheckmarkCircleOutline
                color="var(--color-success)"
                size={32}
              />
              <p>Account connected! Continue below to book your session.</p>
            </Row>
          )}
        </>
      )}
      {createAccount && (
        <>
          <p>
            Create a betstamp account. Set a username and password below then
            continue to book your session. Already have an account?{' '}
            <span
              style={{
                color: 'var(--color-primary)',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                setCreateAccount(false);
                setAlreadyHasAccount(true);
              }}
            >
              Click here to connect your existing account
            </span>
          </p>
          <AuthTextInput
            label="Email"
            name="betstamp_email"
            type="email"
            textContentType={'emailAddress'}
            leftIcon={IoMailOutline}
            rightIcon={
              emailIsAvailable === '' && valids.email
                ? IoCheckmarkCircleOutline
                : null
            }
            rightIconColor={'var(--color-success)'}
            errorText={emailIsAvailable || forceEmailInvalid}
            placeholder={'yourname@gmail.com'}
            containerStyle={{ maxWidth: '500px' }}
            onEndEditing={() => {
              if (!valids.email) setForceEmailInvalid('Invalid email');
            }}
            onChangeText={text => setLogin({ ...login, betstamp_email: text })}
            value={login.betstamp_email}
            autoFocus={!login.betstamp_email}
          />
          <AuthTextInput
            label="Username"
            name="betstamp_username"
            placeholder="username"
            rightIcon={
              usernameIsAvailable === '' && valids.username
                ? IoCheckmarkCircleOutline
                : () => <div style={{ height: '24px', width: '48px' }}></div>
            }
            rightIconColor={'var(--color-success)'}
            errorText={usernameIsAvailable}
            leftIcon={IoPersonCircleOutline}
            containerStyle={{ maxWidth: '500px' }}
            onChangeText={text => setLogin({ ...login, username: text })}
            autoComplete="username"
            autoFocus={!!login.betstamp_email}
          />
          <AuthTextInput
            label="Password"
            name="password"
            placeholder={'Password'}
            containerStyle={{ maxWidth: '500px' }}
            leftIcon={IoLockClosedOutline}
            rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
            rightIconOnPress={() => setShowPassword(!showPassword)}
            errorText={forcePasswordInvalid}
            type={showPassword ? 'text' : 'password'}
            onChangeText={text => setLogin({ ...login, password: text })}
            onEndEditing={() => {
              if (!valids.password) {
                setForcePasswordInvalid('Invalid password');
              }
            }}
            value={login.password}
          />
          <TOS
            acceptedTOS={login?.accepted_TC}
            setAcceptedTOS={val => {
              setLogin({ ...login, accepted_TC: val });
            }}
          />
        </>
      )}
    </>
  );
}
