import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// components
import { AuthButton } from 'components/AuthButton';

import { useMutation } from '@tanstack/react-query';
import { AuthTextInput } from 'components/AuthTextInput';

export default function Unsub() {
  const unsubMut = useMutation({
    mutationKey: 'unsubscribe_from_betstamp_marketing',
  });
  const [email, setEmail] = useState(null);
  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>

      <div
        className="container-full"
        style={{ maxWidth: '650px', textAlign: 'center' }}
      >
        <p>Enter your email below to unsubscribe from the mailing list</p>
        <AuthTextInput
          placeholder="Email"
          onChangeText={text => {
            setEmail(text);
          }}
        />
        <AuthButton
          onPress={() => {
            if (email) {
              unsubMut.mutate({
                endpoint: 'unsubscribe_from_betstamp_marketing',
                body: { email: email },
              });
            }
          }}
          isLoading={unsubMut.isLoading}
          disabled={!email}
        >
          Unsubscribe
        </AuthButton>
      </div>
    </>
  );

  //const dispatch = useDispatch();
  //const reduxProps = useSelector(state => ({
  //  updateSuccess: state.authReducer.updateUserSuccess,
  //  updateFailure: state.authReducer.updateUserFailure,
  //}));
  //const { updateSuccess, updateFailure } = reduxProps;

  //return (
  //  <>
  //    <Helmet>
  //      <title>Unsubscribe</title>
  //    </Helmet>
  //    <div
  //      className="container-full"
  //      style={{ maxWidth: '650px', textAlign: 'center' }}
  //    >
  //      <h3>Unsubscribe from betstamp mailing list?</h3>
  //      {updateSuccess ? (
  //        <p style={{ color: 'var(--color-success)' }}>Unsubscribed!</p>
  //      ) : updateFailure ? (
  //        <p style={{ color: 'var(--color-complement)' }}>
  //          Unsubscribe unsuccessful, please try again later.
  //        </p>
  //      ) : (
  //        <p>It's that simple.</p>
  //      )}
  //      <br />
  //      {!updateSuccess && (
  //        <AuthButton
  //          containerStyle={{
  //            ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
  //            ...AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
  //            display: 'inline-flex',
  //            justifyContent: 'center',
  //          }}
  //          onPress={() => {
  //            dispatch(updateUser({ receive_marketing_emails: false }));
  //          }}
  //        >
  //          Confirm
  //        </AuthButton>
  //      )}
  //      <LinkButton
  //        to="/"
  //        colorTheme="inverted"
  //        containerStyle={{
  //          ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
  //          ...AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
  //          display: 'inline-flex',
  //          justifyContent: 'center',
  //        }}
  //      >
  //        {updateSuccess ? 'Take Me Home' : 'Cancel'}
  //      </LinkButton>
  //      <br />
  //    </div>
  //  </>
  //);
}
